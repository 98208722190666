import {ActivatedRouteSnapshot, CanActivate,  Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';



@Injectable()
export class AuthGuard  implements  CanActivate {

  constructor(private  authService: AuthService, private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {


    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/auth']);
      return false;
    }

  }


}
