import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuditModel} from '../shared/model/audit.model';
import {MessageService} from 'primeng/api';
import {AuditService} from '../shared/services/audit.service';
import {AuditRepository} from '../shared/services/audit.repository';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-audit',
  templateUrl: './add-audit.component.html',
  styleUrls: ['./add-audit.component.css']
})
export class AddAuditComponent implements OnInit {

  @Output()
  audit: EventEmitter<any> = new EventEmitter();

  display: boolean = false;
  myForm: FormGroup;
  transmitVideoOnlineReason: boolean = false;

  constructor(private messageService: MessageService,
              private auditService: AuditService,
              public auditRepository: AuditRepository,
              public langRepository: LangRepository
              ) { }
  ngOnInit() {
    this.myForm = this.formValidation();
  }



  formValidation () {
    return new FormGroup({
      num: new FormControl(null, [Validators.required]),
      nameKaz: new FormControl(null, [Validators.required]),
      nameRus: new FormControl(null, [Validators.required]),
      addressKaz: new FormControl(null, [Validators.required]),
      addressRus: new FormControl(null, [Validators.required]),
      placeCount: new FormControl(null, [Validators.required]),
      buildingNameKaz: new FormControl(null, [Validators.required]),
      buildingNameRus: new FormControl(null, [Validators.required]),
      floor: new FormControl(null, [Validators.required]),
      isReserve: new FormControl(null, [Validators.required]),
      isSilencerExists: new FormControl(null, [Validators.required]),
      isCameraExists: new FormControl(null, [Validators.required]),
      cameraWhyNotHaveId: new FormControl(null, [Validators.required]),
      cameraWhyNotHaveText: new FormControl(null, [Validators.required]),
      isCanTransmitVideo: new FormControl(null, [Validators.required]),
      isBlindAreasExists: new FormControl(null, [Validators.required]),
      isCanTransmitVideoByInternet: new FormControl(null, [Validators.required]),
      cameraWhyNotCanTransmitVideoByInternetId: new FormControl(null, [Validators.required]),
      cameraWhyNotCanTransmitVideoByInternetText: new FormControl(null, [Validators.required]),
      airConditionSystemTypeId: new FormControl(null, [Validators.required]),
      toiletLocationTypeId: new FormControl(null, [Validators.required]),
      silencerWhyNotHaveId: new FormControl(null, [Validators.required]),
      silencerWhyNotHaveText: new FormControl(null, [Validators.required]),
    });
  }


  test(event) {
    if (event.selectedOption.title) {
      this.transmitVideoOnlineReason = true;
    } else {
      this.transmitVideoOnlineReason = false;
    }
  }


  showDialog() {
    this.display = true;
    this.auditRepository.start();
    this.auditRepository.getFreeAudit()
      .subscribe(data => {});
  }

  addAudit() {
      this.auditService.addAudit(this.myForm.value)
        .subscribe(data => {
          this.audit.emit(data.data);
          this.display = false;
        }, error => {
          console.log(error)
          this.addSingle(error.errorMsgRus, 'error');
        })
  }


  reset() {
    this.myForm.reset();
    this.display = false;
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Service Message', detail: text});
  }


}
