import {Http, RequestOptions} from '@angular/http';
import {Injectable} from '@angular/core';
import {BrandNameModel} from '../model/brand-name.model';
import {BaseApi} from '../../../shared/core/base-api';

@Injectable()
export class CollectDataService  extends  BaseApi{

  constructor(public http: Http) {
    super(http);
  }

  getEquipmentData(type: string) {
    return this.get(`api/nct/tech_data/api/ppent/get/${type}`);
  }

  getTechDataStatus() {
    return this.get('api/nct/tech_data/api/techDataStatus');
  }

  updateSilencer(equipment: string, idVpt: number, data: any) {
    return this.put(`api/ppent/update/${equipment}/idVpt/${idVpt}`, data);
  }

  editTechDataStatus(idVpt: number, status: number) {
    return this.put(`/api/nct/tech_data/api/techDataStatus/idVpt/${idVpt}/update/${status}`);
  }

  // Получить конкретные данные оборудования ппент
  getSingleEquipment(type: string, idVpt: number) {
    return this.get(`/api/nct/tech_data/api/manage/ppent/equipment/${type}/idVpt/${idVpt}`);
  }

  // Каталог названии оборудования
  getStaticDataForUnitName() {
    return this.get(`/api/nct/tech_data/api/catalog/equipmentNames`);
  }

  addBrandNameForUnit(data: BrandNameModel) {
    return this.post(`/api/nct/tech_data/api/catalog/add/brandName`, data);
  }

  addFormat(data) {
    return this.post(`/api/nct/tech_data/api/catalog/add/recordingFormat`, data);
  }

  deleteEquipment(name: string,  equipmentId: number) {
    return this.delete(`/api/nct/tech_data/api/ppent/delete/${name}/id/${equipmentId}`);
  }

  getStaticDataForSilencerUnit() {
    return this.get('/api/nct/tech_data/api/getDropDownList/silencer');
  }


  updateSilencerUnit(type: string, data) {
    return this.put(`/api/nct/tech_data/api/ppent/update/${type}`, data);
  }


  addEquipment(type, data) {
    return this.post(`/api/nct/tech_data/api/ppent/add/${type}`, data);
  }

  // загрузка файла для сэс
  uploadFiles(files, type: string) {
    // const formData: FormData = new FormData();
    // formData.append('file', files);
    // let myHeaders = new Headers({
    //   'token': JSON.parse(sessionStorage.getItem('user')).token
    // });
    //
    // return this.http.post(`/ent/bazappent/api/nct/tech_data/api/file/upload/${type}`, formData,  new RequestOptions({ headers: myHeaders }));
  }



  // получить файл для сэс
  getFile(type: string) {
    return this.get(`/api/nct/tech_data/api/file/get/${type}`);
  }

  // список файлов для скачивания
  getDowloadFiles() {
    return this.get(`/ent/bazappent/api/nct/tech_data/api/file/names`);
  }





  getReportFile() {
    return this.get(`/ent/bazappent/api/nct/tech_data/api/file/get/report`);
  }

  contructReport() {
    return this.get(`/api/nct/tech_data/api/ppent/construct/report?lang=RU&format=Pdf`);
  }

  uploadFile(formData: FormData) {
    return this.post(`/ent/bazappent/api/nct/tech_data/api/file/upload/see`, formData);
  }


  allDelete(type) {
    return this.delete(`ent/bazappent/api/nct/tech_data/api/file/delete/${type}`);
  }

  removeFile(id: number) {
    return this.delete(`ent/bazappent/api/nct/tech_data/api/file/delete/id/${id}`)
  }


  getHardwareKey() {
    return this.get(`api/nct/tech_data/api/ppent/hardwareKey/get`);
  }

  addHardwareKey(data) {
    return this.post(`api/nct/tech_data/api/ppent/hardwareKey/add`, data);
  }

  // добавить данные видеозаписи
  addVideoRecord(data) {
    return this.post(`api/nct/tech_data/api/ppent/videoRecord/add`, data);
  }

  getVideoRecord() {
    return this.get(`api/nct/tech_data/api/ppent/videoRecord/get`);
  }

  updateHardwareKey(data) {
    return this.put(`api/nct/tech_data/api/ppent/hardwareKey/update`, data);
  }

  // Данные глушителя по ID
  getSingleEquipmentById(type: string, id: number) {
    return this.get(`api/nct/tech_data/api/ppent/get/${type}/id/${id}`);
  }
}








