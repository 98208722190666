export class ComputerModel {
  constructor(
   public computerId: number = null, /*id оборудования*/
   public idVpt: number = null, /*id vpt*/
   public quantity: number = null, /*Количество камеры*/
   public year: number = null, /*год производства*/
   public brandId: number = null, /*id марки*/
   public manufacturerId: number = null, /*id производителя*/
   public evaluateId: number = null, /*id оценки*/
   public whyNotHaveReason: string = null, /*Причина отсутствия оборудования*/
   public whyNotHaveId: number = null

) {}

}
