import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ReferenceBooksRepository} from '../../shared/services/reference-books.repository';
import {SchoolsService} from '../../shared/services/schools.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-pupil',
  templateUrl: './add-pupil.component.html',
  styleUrls: ['./add-pupil.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AddPupilComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  classNumbers: any[];
  classLang: any[];
  schoolId: number;
  @Output()
  addData: EventEmitter<any> = new EventEmitter();

  constructor(
    private referenceBooksRepository: ReferenceBooksRepository,
    public schoolsService: SchoolsService,
    public messageService: MessageService,
    public langRepository: LangRepository
    ) { }

  ngOnInit() {
    this.myForm = new FormGroup({
      classNumId: new FormControl(null, [Validators.required]),
      langId: new FormControl(null, [Validators.required]),
      pupilCount: new FormControl(null, [Validators.required])
    });
  }

  show(schoolId: number) {
    this.display = true;
    this.schoolId = schoolId;
    this.referenceBooksRepository.getClassNumbers(schoolId)
      .subscribe(data => {
        this.classNumbers = data;
      })
    this.referenceBooksRepository.getLang(schoolId)
      .subscribe(data => {
        this.classLang = data;
      })
  }

  addSchool() {
    this.schoolsService.addPupil(this.schoolId,  this.myForm.value)
      .subscribe(data => {
        this.addData.emit(data.data);
        this.display = false;
        this.myForm.reset();
        }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }



}



