export class MetalloiskatelModel {
  constructor(
      public metalDetectorId: number = null, /*id оборудования*/
      public idVpt: number = null, /*id vpt*/
      public quantity: number = null, /*Количество металлоискателя*/
      public brandId: string = null, /*Марка металлоискателя*/
      public manufacturerId: number = null,
      public reactionDistance: number = null, /*дальность действия*/
      public year: number = null, /*год производства*/
      public evaluateId: string = null, /*Рабочее состояние*/
      public metalDetectorTypeId: string = null, /*тип металлоискателя*/
      public whyNotHaveReason: string = null,  /*причина отсутствия оборудования*/
      public whyNotHaveId: number = null
      ) {  }
}
