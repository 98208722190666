import {NgModule} from '@angular/core';
import {AuditComponent} from './audit.component';
import {AuthRoutingModule} from './audit-routing.module';
import {AuditService} from './shared/services/audit.service';
import {CommonModule} from '@angular/common';
import {SchemeModule} from '../scheme/scheme.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TopLineComponent} from '../top-line/top-line/top-line.component';
import { AuditListsComponent } from './audit-lists/audit-lists.component';
import {MatButtonModule, MatTableModule} from '@angular/material';

import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';

import {MatInputModule} from '@angular/material/input';
import {TableModule} from 'primeng/table';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton'
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {EditAuditComponent} from './edit-audit/edit-audit.component';
import { AddAuditComponent } from './add-audit/add-audit.component';
import { SchemeAuditComponent } from './scheme-audit/scheme-audit.component';
import {DropdownModule} from 'primeng/primeng';
import {AuditRepository} from './shared/services/audit.repository';
import {MessageService} from 'primeng/api';
import {TopLineModule} from '../top-line/top-line.module';
import {LangRepository} from '../shared/lang/lang.repository';
import {LangStaticDataSource} from '../shared/lang/lang.data';
import {ReportComponent} from './report/report.component';

@NgModule({
  declarations: [
    AuditComponent,
    EditAuditComponent,
    AuditListsComponent,
    AddAuditComponent,
    SchemeAuditComponent,
    ReportComponent
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    SchemeModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatInputModule,
    TableModule,
    DropdownModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,
    RadioButtonModule,
    CheckboxModule,
    InputTextModule,
    TopLineModule

  ],
  providers: [AuditService, AuditRepository, MessageService, LangRepository, LangStaticDataSource],
  entryComponents: [
    EditAuditComponent
  ]
})

export class AuditModule {

}
