import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {ConditionerModel} from '../shared/model/conditioner.model';
import {ActivatedRoute} from '@angular/router';
import {s} from '@angular/core/src/render3';
import {EditGlushitelComponent} from '../glushitel/edit-glushitel/edit-glushitel.component';
import {EditConditionerComponent} from './edit-conditioner/edit-conditioner.component';
import {UpdateService} from '../shared/services/update.service';
import {AddAuditComponent} from '../../audit/add-audit/add-audit.component';
import {AddConditionerComponent} from './add-conditioner/add-conditioner.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CatalogRepository} from '../shared/services/catalog.repository';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-conditioner',
  templateUrl: './conditioner.component.html',
  styleUrls: ['./conditioner.component.css']
})
export class ConditionerComponent implements OnInit {

  @ViewChild(AddConditionerComponent)
  addConditionerComponent: AddConditionerComponent;

  @ViewChild(EditConditionerComponent)
  editConditionerComponent: EditConditionerComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  conditionerList: ConditionerModel[];
  currentConditioner: ConditionerModel;
  idVpt: string;

  constructor(
    private collectService: CollectDataService,
    private route: ActivatedRoute,
    public updateService: UpdateService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.idVpt = this.route.snapshot.params['id'];

    this.currentConditioner = new ConditionerModel(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.collectService.getEquipmentData('airConditioner')
      .subscribe(data => {
        console.log(data);
        this.conditionerList = data.list;
      })
  }

  activeAudit(conditioner: ConditionerModel) {
    this.currentConditioner = conditioner;
  }

  edit(Conditioner) {
    this.currentConditioner = Conditioner;
    this.editConditionerComponent.show(this.currentConditioner, this.idVpt);
  }

  update(event) {
    console.log(event);
    this.updateService.update(event, this.conditionerList, 'airConditionerId');
  }

  add() {
    this.addConditionerComponent.show(this.idVpt);
  }

  push(event) {
    this.updateService.dataPush(event, this.conditionerList);
  }


  formValidation(data) {
      return new FormGroup(
        {
          quantity: new FormControl(data.quantity, [Validators.required]),
          year: new FormControl(data.year, [Validators.required]),
          brandId: new FormControl(data.brandId, [Validators.required]),
          manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
          evaluateId: new FormControl(data.evaluateId, [Validators.required]),
          whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required])
        }
      );
  }


  deleteEquipment(Conditioner) {
    this.currentConditioner = Conditioner;
    this.delete.emit({
      equipmentId: this.currentConditioner.airConditionerId,
      equipmentName: 'airConditioner',
      equipmentIdName: 'airConditionerId',
      equipmentLists: this.conditionerList
    });
  }

}
