export class PupilModel {
  constructor(
      public id: number = null, /*Код записи*/
      public classNumNameKaz: string = null, /*Описание номера класса на казахском*/
      public classNumNameRus: string = null, /*Описание номера класса на русском*/
      public langNameKaz: string = null, /*Наименование языка обучения на казахском*/
      public langNameRus: string = null, /*Наименование языка обучения на русском*/
      public pupilCount: number = null /*Количество учеников*/
      ) { }
}

