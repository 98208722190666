import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormValidationSchool} from '../shared/core/form-validation';
import {EditModel} from '../shared/model/edit.model';
import {SchoolsService} from '../shared/services/schools.service';
import {ReferenceBooksService} from '../shared/services/reference-books.service';
import {ReferenceBooksRepository} from '../shared/services/reference-books.repository';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-school',
  templateUrl: './add-school.component.html',
  styleUrls: ['./add-school.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AddSchoolComponent  extends FormValidationSchool implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  showCategory: number;
  value: boolean;
  selectedValue: string;
  profileId: number;
  schoolEduLevel: number;
  correctional: number;
  raionList: any[];
  katoList: any[] = [];
  profileStatus: number;

  @Output()
  schoolInfo: EventEmitter<any> = new EventEmitter();

  constructor(
    private schoolsService: SchoolsService,
    private referenceBook: ReferenceBooksService,
    public referenceBooksRepository: ReferenceBooksRepository,
    private messageService: MessageService,
    public langRepository: LangRepository
  ) {
    super();
  }

  ngOnInit() {
    this.myForm = this.formValidation(new EditModel());
  }

  getRaion(value) {
    this.katoList = [];
    this.raionList = this.referenceBooksRepository.getRaion(value);
  }

  schoolEduLevelId(value) {
    this.schoolEduLevel = value;
  }

  showDisplay() {
    this.display = true;
    this.referenceBooksRepository.start();
  }

  schoolCategory(id: number) {
    this.referenceBooksRepository.getSchoolCategory(id)
      .subscribe(data => {

      })
  }

  show(status) {
     this.showCategory = status;
  }

  profile(status: number) {
    this.profileStatus = status;
  }


  schoolProfileId(status: number) {
    this.profileId = status;
  }

  test(value: number) {
    this.myForm.value.isCorrectional = value;

  }

  isCorrectional(status: number) {
    this.correctional = status;
  }

  addSchool() {
    for (let key in this.myForm.value) {
      if (key.slice(-2) === 'Id' || key.substring(0, 2) === 'is' ) {
        this.myForm.value[key] = +this.myForm.value[key];
      }
    }

    this.schoolsService.addSchool(this.myForm.value)
      .subscribe(data => {
        const schoolInfo = {
          oblId: this.myForm.value.oblId,
          raionId: this.myForm.value.raionId,
          schoolTypeId: this.myForm.value.schoolTypeId,
          data: data.data
        };

        this.addSingle('Школа успешно добавлена');

        this.schoolInfo.emit(schoolInfo);
        this.reset();
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })

  }

  getKato(oblId: number, raionId: number, index: number,  kato = 0) {
        // this.myForm.value.katoId = kato;
        if (index === null) {
          this.katoList = [];
        }
        this.referenceBooksRepository.getKato(oblId, raionId, kato)
          .subscribe(data => {
            if (this.katoList.length - 1 !==  index) {
              this.katoList.splice(index + 1, this.katoList.length - index + 1);
            }
            if (data.length) {
              this.katoList.push(data);
            }
          }, error => {})
  }

  reset() {
    this.showCategory = null;
    this.correctional = null;
    this.profileId = null;
    this.profileStatus = null;
    this.schoolEduLevel = null;
    this.katoList = null;
    this.myForm.reset();
    this.display = false;
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }



}
