export class RegisterModel {
  constructor(
      public id: number = null , /*Код ППЕНТ*/
      public nameKaz: string = null , /*Наименование ППЕНТ на казахском*/
      public nameRus: string = null, /*Наименование ППЕНТ на русском*/
      public shortNameKaz: string = null, /*Сокращенное наименование ППЕНТ на казахском*/
      public shortNameRus: string = null, /*Сокращенное наименование ППЕНТ на русском*/
      public addressKaz: string = null, /*Адрес ППЕНТ на казахском*/
      public addressRus: string = null, /*Адрес ППЕНТ на русском*/
      public phoneNumber: string = null, /*Номер телефона ППЕНТ*/
      public email: string = null, /*Адрес электронной почты ППЕНТ*/
      public directorFullname: string = null, /*Ф.И.О. руководителя ППЕНТ*/
      public directorBirthDate: string = null, /*Дата рождения директора ППЕНТ*/
      public directorHomePhoneNumber: string = null, /*Номер домашнего телефона руководителя ППЕНТ*/
      public directorWorkPhoneNumber: string = null, /*Номер рабочего телефона руководителя ППЕНТ*/
      public directorMobilePhoneNumber: string = null, /*Номер мобильного телефона руководителя ППЕНТ*/
      public engineerFullname: string = null, /*Ф.И.О. эксперта ППЕНТ*/
      public engineerBirthDate: string = null, /*Дата рождения инженера ППЕНТ*/
      public engineerHomePhoneNumber: string = null, /*Номер домашнего телефона эксперта ППЕНТ*/
      public engineerWorkPhoneNumber: string = null, /*Номер рабочего телефона эксперта ППЕНТ*/
      public engineerMobilePhoneNumber: string = null /*Номер мобильного телефона эксперта ППЕНТ*/

) {}
}
