import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OutlineComponent} from './outline/outline.component';
import {PrintRepository} from './print.repository';
import {LangRepository} from './shared/lang/lang.repository';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LangStaticDataSource} from './shared/lang/lang.data';


@NgModule({
  declarations: [
    OutlineComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule
  ],
  providers: [PrintRepository, LangRepository, LangStaticDataSource ],
  exports:[OutlineComponent]
})

export class SchemeModule {

}
