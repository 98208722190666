import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LangRepository} from '../../../shared/lang/lang.repository';
import {SchoolsService} from "../../shared/services/schools.service";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-school-table',
  templateUrl: './school-table.component.html',
  styleUrls: ['./school-table.component.css']
})
export class SchoolTableComponent implements OnInit, OnChanges {

  @Input()
  schoolLists: any[];

  @Output()
  fullInfo = new EventEmitter();

  schoolLength: number;
  school: any = [];
  schoolFilterLists: any = [];
  showOnePage = 10;
  schoolId: number;
  raionId: number;
  oblId: number;
  currentSchoolId: number;
  typeSchool: number;


  constructor(
    private route: ActivatedRoute,
    public langRepository: LangRepository,
    public schoolsService: SchoolsService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService
  ) { }

  ngOnInit() {


  }

  ngOnChanges() {

  }

  search(text) {
    this.schoolFilterLists = this.school.filter(o =>
      Object.keys(o).some(k =>
        o[k].toString().toLowerCase().indexOf(text) !== -1));
  }

  currentSchool(school) {
    console.log(school);
    this.currentSchoolId = school.id;
    sessionStorage.setItem('schoolId', this.currentSchoolId.toString());
    const ppentInfo = {
      ppent: school,
      oblId: this.oblId,
      raionId: this.raionId
    };
    this.fullInfo.emit(ppentInfo);
  }


  updateSchool(event) {
    console.log(event)
    this.raionId = event.raionId;
    this.oblId = event.oblId;
    this.school = event.school;
    this.schoolFilterLists = event.school;
    this.typeSchool = event.typeId;
    console.log(this.school);
    if (sessionStorage.getItem('schoolId')) {
      const ppentId = this.route.snapshot.queryParams.ppentId;
      const find = this.school.find(item => item.id === +sessionStorage.getItem('schoolId'));
      if (find) {
        this.currentSchool(find);
      }
    }
  }


  openCloseDataBase(school) {
    console.log(event);
    this.confirmationService.confirm({
      message: school.orgStatusId === 2 ? this.langRepository.wordList[170] : this.langRepository.wordList[169],
      accept: () => {
        if (school.orgStatusId === 2) {
          this.schoolsService.closeSchoolDb(school.id)
            .subscribe(data => {
              this.messageService.add({severity:'success', summary:'Service Message', detail:this.langRepository.wordList[167]});
              school.orgStatusId = 3;
              school.orgStatusNameKaz = data.data.orgStatusNameKaz;
              school.orgStatusNameRus = data.data.orgStatusNameRus;
            })
        } else {
          this.schoolsService.openSchoolDb(school.id)
            .subscribe(data => {
              this.messageService.add({severity:'success', summary:'Service Message', detail: this.langRepository.wordList[166]});
              school.orgStatusId = 2;
              school.orgStatusNameKaz = data.data.orgStatusNameKaz;
              school.orgStatusNameRus = data.data.orgStatusNameRus;
            })
        }
      }
    });
  }

}
