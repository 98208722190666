import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {KeyModel} from "../../shared/model/key.model";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {UpdateCallback} from "@angular/core/src/testability/testability";
import {UpdateService} from "../../shared/services/update.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-key',
  templateUrl: './edit-key.component.html',
  styleUrls: ['./edit-key.component.css']
})
export class EditKeyComponent implements OnInit {

  myForm: FormGroup;
  display: boolean = false;

  @Output()
  updateKey: EventEmitter<KeyModel> = new EventEmitter();

  constructor(
    public catalogRepository: CatalogRepository,
    public collectDataService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new KeyModel());
  }

  formValidation(data: KeyModel) {
    return new FormGroup(
      {
        id: new FormControl(data.id, [Validators.required]),
        inumber: new FormControl(data.inumber, [Validators.required]),
        inOrg: new FormControl(data.inOrg, [Validators.required]),
      }
    )
  }


  show(data: KeyModel) {
    console.log(data);
    this.myForm = this.formValidation(data);
    this.display = true;
  }

  edit() {

    this.collectDataService.updateHardwareKey(this.myForm.value)
      .subscribe(data => {
        this.updateKey.emit(data.data);
        this.display =  false;
        this.updateService.addSingle('Аппаратный ключ обновлен');
      }, error => {
        console.log(error);
      })
  }

}
