import { Component, OnInit } from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {Headers, Http, RequestOptions, ResponseContentType} from '@angular/http';
import {UpdateService} from '../shared/services/update.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  file: boolean = false;
  fileLists = [];
  seeLists: any = [];
  reportLists: any = [];
  errorMsg: string;
  checked: boolean;

  constructor(
    public collectDataService: CollectDataService,
    public http: Http,
    public _http: HttpClient,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.getReport();
    // this.getSee();

  }

  getSee() {
    this.collectDataService.getFile('ses')
      .subscribe(data => {
        this.seeLists = data.dataList;
      }, error => {
        console.log(error.json());
      })
  }

  getReport() {
    return this.collectDataService.getDowloadFiles()
      .subscribe(data => {
        this.reportLists = data.data;
      }, error => {
        console.log(error);
      })
  }


  upload(files, type: string) {
    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    let myHeaders = new Headers({
      'token': JSON.parse(sessionStorage.getItem('user')).token
    });


    return this.http.post(`./api/nct/tech_data/api/file/upload/?fileContent=${type}`, formData, new RequestOptions({ headers: myHeaders }))
      .subscribe(data => {
        this.reportLists = data.json().data;
        this.updateService.addSingle('Файл успешно загружен')
      }, error => {
        this.updateService.addSingle('Не удалось загрузить файл', 'error');
      })
  }

  download(src: string) {
    window.open(`./files/techdata/${src}`);
  }

  delete(id, type) {
    return this.collectDataService.removeFile(id)
      .subscribe(data => {
        let find = this.reportLists.findIndex(item => item.id === id);
        this.reportLists.splice(find, 1);
        this.updateService.addSingle('Файл успешно удален');
      }, error => {
        this.updateService.addSingle('Не удалось удалить файл', 'error')
      })
  }


  contructReport() {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    const headers = new HttpHeaders({'token': JSON.parse(sessionStorage.getItem('user')).token});
    window.open(`./api/nct/tech_data/api/ppent/construct/report?lang=RU&format=Pdf&token=${token}`)
  }




}
