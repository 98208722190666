import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {EditPrinterComponent} from "./edit-printer/edit-printer.component";
import {AddPrinterComponent} from "./add-printer/add-printer.component";
import {CollectDataService} from "../shared/services/collect-data.service";
import {UpdateService} from "../shared/services/update.service";
import {PrinterModel} from "../shared/model/printer.model";
import {CatalogRepository} from "../shared/services/catalog.repository";
import {ActivatedRoute} from "@angular/router";
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {

  printerLists: any[];
  currentPrinter: PrinterModel;
  idVpt: string;

  constructor(
    private collectService: CollectDataService,
    public updateService: UpdateService,
    public catalogRepository: CatalogRepository,
    public route: ActivatedRoute,
    public langRepository: LangRepository
  ) { }

  @ViewChild(EditPrinterComponent)
  editPrinterComponent: EditPrinterComponent;

  @ViewChild(AddPrinterComponent)
  addPrinterComponent: AddPrinterComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();


  ngOnInit() {
    this.currentPrinter = new PrinterModel();

    this.idVpt = this.route.snapshot.params['id'];


    this.collectService.getEquipmentData('printer')
      .subscribe(data => {
        console.log(data);
        this.printerLists = data.list;
      })
  }

  add() {
    this.addPrinterComponent.show();
  }

  edit(printer) {
    this.currentPrinter = printer;
    this.editPrinterComponent.show(this.currentPrinter, this.idVpt);
  }

  update(event) {
    this.updateService.update(event, this.printerLists, 'printerId');
  }

  activeAudit(event) {
    this.currentPrinter = event;
  }


  push(event) {
    this.updateService.dataPush(event, this.printerLists);
  }

  deleteEquipment(printer) {
    this.currentPrinter = printer;
      this.delete.emit({
        equipmentId: this.currentPrinter.printerId,
        equipmentName: 'printer',
        equipmentIdName: 'printerId',
        equipmentLists: this.printerLists
      });
  }







}
