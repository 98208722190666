import {Component, EventEmitter, OnChanges, OnInit, Output} from '@angular/core';
import {SchoolsService} from '../../../shared/services/schools.service';
import {ReferenceBooksRepository} from '../../../shared/services/reference-books.repository';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SearchInfoModel} from '../../../shared/model/search-info.model';
import {SelectItem} from 'primeng/api';
import {LangRepository} from '../../../../shared/lang/lang.repository';

@Component({
  selector: 'app-search-school',
  templateUrl: './search-school.component.html',
  styleUrls: ['./search-school.component.css']
})
export class SearchSchoolComponent implements OnInit, OnChanges {


  oblListKaz: SelectItem[] = [];
  oblListRus: SelectItem[] = [];
  ppentList: SelectItem[] = [];
  oblPpentList: SelectItem[] = [];
  raiOblKaz: SelectItem[] = [];
  raiOblRus: SelectItem[] = [];
  typeListsKaz: SelectItem[] = [];
  typeListsRus: SelectItem[] = [];
  myForm: FormGroup;


  @Output()
  currentSchoolData = new EventEmitter();


  constructor(
    private schoolService: SchoolsService,
    private route: ActivatedRoute,
    public langRepository: LangRepository,

  ) { }



  ngOnInit() {
    this.myForm = this.formValidation(new SearchInfoModel());
    this.start();

  }

  ngOnChanges() {

  }



  start() {
    this.schoolService.getObl()
      .subscribe(data => {
        this.pushArray(data.items, this.oblListKaz, 'nameKaz' );
        this.pushArray(data.items, this.oblListRus, 'nameRus' );
        if (this.route.snapshot.queryParams.oblId) {
          const schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'));
          console.log(schoolInfo)
          this.myForm = this.formValidation(new SearchInfoModel( schoolInfo.oblId));
          this.getRaiObl(schoolInfo.oblId, true)

        }
      }, error => {

      });
    this.getScoolTypeList();
  }

  getScoolTypeList() {
    this.schoolService.getSchoolTypeList()
      .subscribe(data => {
        this.pushArray(data.items, this.typeListsKaz, 'shortNameKaz');
        this.pushArray(data.items, this.typeListsRus, 'shortNameRus');
      })
  }


  formValidation (data?: any) {
    return new FormGroup({
      oblId: new FormControl(data.oblId , [Validators.required]),
      raionId: new FormControl(data.raionId , [Validators.required]),
      typeId: new FormControl(data.typeId, [Validators.required])
    });
  }

  getRaiObl(oblId: string, begin: boolean = false) {
    this.raiOblKaz = [];
    this.raiOblRus = [];
    this.schoolService.getRaionObl(oblId)
      .subscribe(data => {
        this.pushArray(data.items, this.raiOblKaz, 'nameKaz');
        this.pushArray(data.items, this.raiOblRus, 'nameRus');
        if (begin) {
          const schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'));
          this.myForm = this.formValidation(new SearchInfoModel(schoolInfo.oblId, schoolInfo.ppentId, schoolInfo.oblPpent, schoolInfo.raionId, schoolInfo.typeId));
          this.getSchool();
        }
      })
  }

  getSchool() {
    this.schoolService.getSchools(
      this.myForm.value.oblId,
      this.myForm.value.raionId,
      this.myForm.value.typeId
    )
      .subscribe(data => {
        const schoolInfo = {
          school: data.items,
          oblId: this.myForm.value.oblId,
          raionId: this.myForm.value.raionId,
          typeId: this.myForm.value.typeId
        };
        sessionStorage.setItem('schoolInfo', JSON.stringify(schoolInfo));
        this.currentSchoolData.emit(schoolInfo);
      });
  }

  reset() {
    this.oblPpentList = [];
    this.ppentList = [];
    this.raiOblKaz = [];
    this.raiOblRus = [];
  }


  pushArray(data, arr, type = 'shortNameRus') {
    data.forEach(item => {
      arr.push({
        label: item[type],
        value: item.id
      })
    })
  }
}
