import {NgModule} from '@angular/core';
import {SchoolsComponent} from './schools.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SchoolsRoutingModule} from './schools-routing.module';
import {SchoolsService} from './shared/services/schools.service';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import { SearchSchoolComponent } from './school-list/school-table/search-school/search-school.component';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import { FullInfoComponent } from './full-info/full-info.component';
import { SchoolTableComponent } from './school-list/school-table/school-table.component';
import {CommonModule} from '@angular/common';
import { EditSchoolComponent } from './full-info/edit-school/edit-school.component';
import {ConfirmDialogModule, InputTextModule} from 'primeng/primeng';
import {ReferenceBooksService} from './shared/services/reference-books.service';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {ReferenceBooksRepository} from './shared/services/reference-books.repository';
import { AddSchoolComponent } from './add-school/add-school.component';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ToastModule} from 'primeng/toast';
import { PupilCountListComponent } from './pupil-count-list/pupil-count-list.component';
import { SchoolListComponent } from './school-list/school-list.component';
import { PupilEditComponent } from './pupil-count-list/pupil-edit/pupil-edit.component';
import { AddPupilComponent } from './pupil-count-list/add-pupil/add-pupil.component';
import { PupilByDirectionComponent } from './pupil-by-direction/pupil-by-direction.component';
import { AddPupilDirectionComponent } from './pupil-by-direction/add-pupil-direction/add-pupil-direction.component';
import { EditPupilDirectionComponent } from './pupil-by-direction/edit-pupil-direction/edit-pupil-direction.component';
import {PaginatorModule} from 'primeng/paginator';
import {AuditModule} from '../audit/audit.module';
import {TopLineComponent} from '../top-line/top-line/top-line.component';
import {TopLineModule} from '../top-line/top-line.module';
import { ReportComponent } from './school-list/report/report.component';



@NgModule({
  declarations: [
    SchoolsComponent,
    SearchSchoolComponent,
    FullInfoComponent,
    SchoolTableComponent,
    EditSchoolComponent,
    AddSchoolComponent,
    PupilCountListComponent,
    SchoolListComponent,
    PupilEditComponent,
    AddPupilComponent,
    PupilByDirectionComponent,
    AddPupilDirectionComponent,
    EditPupilDirectionComponent,
    ReportComponent

  ],
  imports: [
    FormsModule,
    SchoolsRoutingModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    TableModule,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ListboxModule,
    MultiSelectModule,
    CheckboxModule,
    RadioButtonModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule,
    PaginatorModule,
    TopLineModule,


  ],
  providers: [SchoolsService, ReferenceBooksService, ReferenceBooksRepository]
})


export class SchoolsModule {

}
