import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CollectDataService} from "../../shared/services/collect-data.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UpdateService} from "../../shared/services/update.service";
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {CameraModel} from "../../shared/model/camera.model";
import {ComputerModel} from "../../shared/model/computer.model";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-computer',
  templateUrl: './add-computer.component.html',
  styleUrls: ['./add-computer.component.css']
})
export class AddComputerComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  computerStatus: boolean = false;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor(
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new ComputerModel());
  }


  add() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.addEquipment('computer', this.myForm.value)
      .subscribe(data => {
        console.log(data);
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }


  formValidation(data: ComputerModel) {
    return new FormGroup(
      {
        idVpt: new FormControl(data.idVpt, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        computerId: new FormControl(data.computerId, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required])
      }
    )
  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }

  count(value) {
    this.computerStatus = this.updateService.count(value);
  }


  show(idvpt) {
    this.display = true;
  }


}
