import { Injectable, OnInit} from '@angular/core';
import { Language } from './lang.model';
import {LangStaticDataSource} from './lang.data';


@Injectable()
export class LangRepository  {
  public wordList = [];
  public langStatus: number;

  constructor(private dataSource: LangStaticDataSource) {
    const language = localStorage.getItem('lang') || 'rus';
    if (language === 'kaz') {
      this.langStatus = 1;
    } else {
      this.langStatus = 2;
    }
    this.getLang(language);

  }

  getLang(lang: string) {
    console.log(lang);
    if (lang === 'kaz') {
      this.langStatus = 1;
    } else {
      this.langStatus = 2;
    }
    localStorage.setItem('lang', lang);

    this.dataSource.getLang()
      .subscribe(data => {
        this.wordList = data.map(l => l[lang]);
      })
  }

}
