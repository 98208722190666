import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {GlushitelModel} from '../shared/model/glushitel.model';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CatalogRepository} from '../shared/services/catalog.repository';
import {EditGlushitelComponent} from './edit-glushitel/edit-glushitel.component';
import {AddGlushitelComponent} from './add-glushitel/add-glushitel.component';
import {UpdateService} from '../shared/services/update.service';
import {ConfirmationService} from 'primeng/api';
import {LangRepository} from '../../shared/lang/lang.repository';




@Component({
  selector: 'app-glushitel',
  templateUrl: './glushitel.component.html',
  styleUrls: ['./glushitel.component.css']
})
export class GlushitelComponent implements OnInit {

  glushLists: GlushitelModel[]  = [];
  currentGlush: GlushitelModel;
  myForm: FormGroup;
  idVpt: string;


  @ViewChild(EditGlushitelComponent)
  editGlushitelComponent: EditGlushitelComponent;

  @ViewChild(AddGlushitelComponent)
  addGlushitelComponent: AddGlushitelComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  constructor(
    private collectService: CollectDataService,
    private route: ActivatedRoute,
    public catalogRepository: CatalogRepository,
    private updateService: UpdateService,
    public confirmationService: ConfirmationService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = new FormGroup({});
    this.idVpt = this.route.snapshot.params['id'];

    this.currentGlush = new GlushitelModel(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.collectService.getEquipmentData('silencer')
      .subscribe(data => {
        console.log(data, 'Глушитель');
        this.glushLists = data.list;
      }, error => {
        console.log(error);
    });

    // this.collectService.getStaticDataForSilencerUnit()
    //   .subscribe(data => {
    //   });

  }

  activeSilencer(audit) {
    console.log(audit);
    this.currentGlush = audit;
  };



  edit() {
    this.editGlushitelComponent.show(this.currentGlush, this.idVpt);
  }

  add() {
    this.addGlushitelComponent.show(this.idVpt);
  }


  push(event) {
    console.log(event, 'нов');
    console.log(this.glushLists)
    this.updateService.dataPush(event, this.glushLists);
  }

  updateSilencer(type: string, idVpt: string) {
    this.collectService.updateSilencer('silencer', this.currentGlush.idVpt, this.myForm.value)
      .subscribe(data => {
        console.log(data);
      })
  }


  update(event: GlushitelModel) {
    this.updateService.update(event, this.glushLists, 'silencerId');
  }

  deleteGlush() {
    this.delete.emit({
      equipmentId: this.currentGlush.silencerId,
      equipmentName: 'silencer',
      equipmentIdName: 'silencerId',
      equipmentLists: this.glushLists
    });
  }






}







