import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SchoolsService} from '../../shared/services/schools.service';
import {EditPupleDirectionModel} from '../../shared/model/edit-puple-direction.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-pupil-direction',
  templateUrl: './edit-pupil-direction.component.html',
  styleUrls: ['./edit-pupil-direction.component.css']
})
export class EditPupilDirectionComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  schoolId: number;
  recordId: number;

  @Input()
  classLang: any[];

  @Input()
  directionList: any[];

  @Output()
  editData: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  getDirectionLists: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private schoolsService: SchoolsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new EditPupleDirectionModel());
  }

  getDirection() {
    this.getDirectionLists.emit();
  }

  show(schoolId: number, recordId: number) {
    this.schoolId = schoolId;
    this.recordId = recordId
    this.display = true;
    this.getDirection();
    this.schoolsService.getSinglePupilByDirectionCount(schoolId, recordId)
      .subscribe(data => {
        this.myForm = this.formValidation(data.data);
      })
  }

  formValidation(data: any) {
    return new FormGroup({
      studyDirectionId: new FormControl(data.studyDirectionId, [Validators.required]),
      langId: new FormControl(data.langId, [Validators.required]),
      pupilCount: new FormControl(data.pupilCount, [Validators.required])
    });
  }


  edit() {
      this.schoolsService.editDirectionPupil(this.schoolId, this.recordId, this.myForm.value)
        .subscribe(data => {
          this.editData.emit(data.data);
          this.reset();
        }, error => {
          this.addSingle(error.errorMsgRus);
        })
  }

  reset() {
    this.myForm.reset();
    this.display = false;
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }



}
