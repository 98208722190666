import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AddScannerComponent} from "./add-scanner/add-scanner.component";
import {EditScannerComponent} from "./edit-scanner/edit-scanner.component";
import {UpdateService} from "../shared/services/update.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ScannerModel} from "../shared/model/scanner.model";
import {CollectDataService} from "../shared/services/collect-data.service";
import {CatalogRepository} from "../shared/services/catalog.repository";
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit {

  @ViewChild(AddScannerComponent)
  addScannerComponent: AddScannerComponent;


  @ViewChild(EditScannerComponent)
  editScannerComponent: EditScannerComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  myForm: FormGroup;
  public scannerLists: any[];
  currentScanner: ScannerModel;
  idVpt: string;


  constructor(
    public updateService: UpdateService,
    public collectService: CollectDataService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.currentScanner = new ScannerModel();

    this.collectService.getEquipmentData('scanner')
      .subscribe(data => {
        console.log(data);
        this.scannerLists = data.list;
      })

    this.myForm = this.formValidation(new ScannerModel());
  }


  add() {
    this.addScannerComponent.show();
  }

  edit(scanner) {
    this.currentScanner = scanner;
    this.editScannerComponent.show(this.currentScanner, this.idVpt);
  }

  update(event) {
    console.log(event);
    this.updateService.update(event, this.scannerLists, 'scannerId');
  }

  push(event) {
    console.log(event);
    this.updateService.dataPush(event, this.scannerLists);
  }

  current(event) {
    this.currentScanner = event;
  }

  formValidation(data) {
    return new FormGroup(
      {
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required])
      }
    )
  }


  deleteEquipment(scanner) {
    this.currentScanner = scanner;
    this.delete.emit({
      equipmentId: this.currentScanner.scannerId,
      equipmentName: 'scanner',
      equipmentIdName: 'scannerId',
      equipmentLists: this.scannerLists
    });
  }

}
