import {FormControl, FormGroup, Validators} from '@angular/forms';


export class FormValidationSchool {
  constructor() {

  }

  formValidation(data?: any) {
    return new FormGroup({
      oblId: new FormControl(data.oblId || null, [Validators.required]),
      raionId: new FormControl(data.raionId || null, [Validators.required]),
      katoId: new FormControl(data.katoId || null, [Validators.required]),
      bin: new FormControl(data.bin || null, [Validators.required]),
      schoolTypeId: new FormControl(data.schoolTypeId || null, [Validators.required]),
      nameKaz:  new FormControl(data.nameKaz , [Validators.required]),
      nameRus:  new FormControl(data.nameRus, [Validators.required]),
      shortNameKaz:  new FormControl(data.shortNameKaz, [Validators.required]),
      shortNameRus:  new FormControl(data.shortNameRus, [Validators.required]),
      addressKaz:  new FormControl(data.addressKaz, [Validators.required]),
      addressRus:  new FormControl(data.addressRusl, [Validators.required]),
      phoneNumber:  new FormControl(data.phoneNumber, [Validators.required]),
      email:  new FormControl(data.email, [Validators.required]),
      schoolOwnershipId:  new FormControl(data.schoolOwnershipId, [Validators.required]),
      schoolDepartmentId:  new FormControl(data.schoolDepartmentId, [Validators.required]),
      schoolLocationId:  new FormControl(data.schoolLocationId, [Validators.required]),
      schoolCategoryId:  new FormControl(data.schoolCategoryId, [Validators.required]),
      schoolEduLevelId:  new FormControl(data.schoolEduLevelId, [Validators.required]),
      schoolProfileId:  new FormControl(data.schoolProfileId, [Validators.required]),
      isCorrectional:  new FormControl(data.isCorrectional, [Validators.required]),
      isIncomplete:  new FormControl(data.isIncomplete, [Validators.required]),
      isSanatorium:  new FormControl(data.isSanatorium, [Validators.required]),
      isMilitary:  new FormControl(data.isMilitary, [Validators.required]),
      isInternational:  new FormControl(data.isInternational, [Validators.required]),
      totalCompCount:  new FormControl(data.totalCompCount, [Validators.required]),
      internetCompCount:  new FormControl(data.internetCompCount, [Validators.required]),
      netSpeedId:  new FormControl(data.netSpeedId, [Validators.required]),
      distanceToPpent:  new FormControl(data.distanceToPpent, [Validators.required]),
      distanceToRegionCenter:  new FormControl(data.distanceToRegionCenter, [Validators.required]),
      distanceToDistrictCenter:  new FormControl(data.distanceToDistrictCenter, [Validators.required]),
      studentDeliveryTimeId:  new FormControl(data.studentDeliveryTimeId, [Validators.required]),
      adminUserName:  new FormControl(data.adminUserName, [Validators.required]),
      adminPhoneNumber:  new FormControl(data.adminPhoneNumber, [Validators.required]),
      schoolEduLangIdList:  new FormControl(data.schoolEduLangIdList, [Validators.required]),
      schoolForeignLangIdList:  new FormControl(data.schoolForeignLangIdList, [Validators.required])
    });
  }
}
