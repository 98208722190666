export class ConditionerModel {
  constructor(
    public airConditionerId: number = null, /*id оборудования*/
    public idVpt: number = null, /*id vpt*/
    public quantity: number = null, /*Количество кондиционера*/
    public brandId: string = null, /*Марка кондиционера*/
    public manufacturerId: string = null, /*Страна производство*/
    public year: number = null, /*год производства*/
    public evaluateId: string = null, /*Рабочее состояние*/
    public whyNotHaveReason: string = null, /*причина отсутствия оборудования*/
    public whyNotHaveId: number = null
    ) { }
}
