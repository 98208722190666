import {FormArray, FormControl} from '@angular/forms';

export class EditModel {
  constructor(
              public katoCode: string = null, /*Код КАТО*/
              public id: number = null, /*Код организации образования*/
              public oblId: number = null, /*Код области, где находится организация образования*/
              public raionId: number = null, /*Код района, где находится организация образования*/
              public katoIdList: any = null, /*Дерево элементов расположения по КАТО*/
              public bin: string = null, /*БИН*/
              public schoolTypeId: number = null, /*Тип организации образования*/
              public nameKaz: string = null, /*Полное наименование на казахском*/
              public nameRus: string = null, /*Полное наименование на русском*/
              public shortNameKaz: string = null, /*Сокращенное наименование на казахском*/
              public shortNameRus: string = null, /*Сокращенное наименование на русском*/
              public addressKaz: string = null, /*адрес на казахском*/
              public addressRus: string = null, /*адрес на русском*/
              public phoneNumber: string = null, /*Номер телефона школы*/
              public email: string = null, /*Адрес электронной почты школы*/
              public schoolOwnershipId: number = null, /*Форма собственности*/
              public schoolDepartmentId: number = null, /*Ведомственная принадлежность*/
              public schoolLocationId: number = null, /*Территориальная принадлежность*/
              public schoolCategoryId: number = null, /*Вид организации образования*/
              public schoolEduLevelId: number = null, /*Уровень образования*/
              public schoolProfileId: number = null, /*Профиль организации образования*/
              public isCorrectional: number = null, /*Является ли школой при исправительном учреждении (1-да,0-нет)*/
              public isIncomplete: number = null, /*Является ли малокомплектной школой (1-да,0-нет)*/
              public isSanatorium: number = null, /*Является ли санаторной школой (1-да,0-нет)*/
              public isMilitary: number = null, /*Является ли военной школой (1-да,0-нет)*/
              public isInternational: number = null, /*Является ли международной школой (1-да,0-нет)*/
              public totalCompCount: number = null, /*Количество компьютеров, используемых в учебно-воспитательном процессе*/
              public internetCompCount: number = null, /*из них, имеющих доступ в интернет*/
              public netSpeedId: number = null, /*Фактическая скорость интернета*/
              public distanceToPpent: number = null, /*Расстояние до ППЕНТ в километрах*/
              public distanceToRegionCenter: number = null, /*Расстояние до областного центра в километрах*/
              public distanceToDistrictCenter: number = null, /*Расстояние до районного центра в километрах*/
              public studentDeliveryTimeId: number = null, /*Характеристика доставки на ЕНТ*/
              public adminUserName: string = null, /*Ф.И.О. ответственного лица организации образования*/
              public adminPhoneNumber: string = null, /*Контактные номера телефонов ответственного лица организации образования*/
              public schoolEduLangIdList: number[] = null, /*Языки обучения организации образования*/
              public schoolForeignLangIdList: number[] = null, /*Изучаемые иностранные языки в организации образования*/
              public ppentId: number = null /*Код ППЕНТ, который работает с данной организацией образования*/

) {}
}
