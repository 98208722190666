import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReferenceBooksService} from '../shared/services/reference-books.service';
import {PupilModel} from '../shared/model/pupil.model';
import {SchoolsService} from '../shared/services/schools.service';
import {SinglePupleModel} from '../shared/model/single-puple.model';
import {PupilEditComponent} from './pupil-edit/pupil-edit.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuditModel} from '../../audit/shared/model/audit.model';
import {AddPupilComponent} from './add-pupil/add-pupil.component';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-pupil-count-list',
  templateUrl: './pupil-count-list.component.html',
  styleUrls: ['./pupil-count-list.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PupilCountListComponent implements OnInit {

  pupilList: PupilModel[];
  currentPupilId: number;
  schoolId: number;
  name: string;

  @ViewChild(PupilEditComponent)
  pupilEditComponent: PupilEditComponent;

  @ViewChild(AddPupilComponent)
  addPupilComponent: AddPupilComponent;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private referenceBooksService: ReferenceBooksService,
    public schoolsService: SchoolsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.name = this.route.snapshot.queryParams.name;
    this.schoolId = +this.route.snapshot.queryParams.schoolId;
    this.schoolsService.getPupilCountList(this.schoolId)
      .subscribe(data => {
        this.pupilList = data.items;
      })
  }

  currentPupil(id: number) {
    this.currentPupilId = id;
  }

  edit(id: number) {
    this.pupilEditComponent.getSinglePupil(this.schoolId, id)
  }

  updateData(event) {
    const find = this.pupilList.find(item => event.id === item.id);
    if (find) {
      for (let key in event) {
        find[key] = event[key];
      }
      this.addSingle(this.langRepository.wordList[133], 'success');
    } else {
      this.pupilList.push(event);
      this.addSingle(this.langRepository.wordList[134], 'success');
    }
  }


  delete(id: number) {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[135],
      accept: () => {
        this.schoolsService.deletePupil(this.schoolId, id)
          .subscribe(data => {
            const findIndex = this.pupilList.findIndex(item => item.id === id);
            this.pupilList.splice(findIndex, 1);
            this.addSingle(this.langRepository.wordList[136], 'success')
          }, error => {
            this.addSingle(error.errorMsgRus);
          });
     }
    });
  }

  addPupil() {
    this.addPupilComponent.show(this.schoolId);
  }

  back() {
    const schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'));
    this.router.navigate(['/schools'], { queryParams: { oblId: schoolInfo.oblId}});
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }



}
