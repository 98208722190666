import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {CameraModel} from '../shared/model/camera.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {EditCameraComponent} from './edit-camera/edit-camera.component';
import {UpdateService} from '../shared/services/update.service';
import {AddCameraComponent} from './add-camera/add-camera.component';
import {CatalogRepository} from '../shared/services/catalog.repository';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {

  cameraLists: CameraModel[];
  currentVideoRegistrator: CameraModel;
  myForm: FormGroup;
  idVpt: string;

  @ViewChild(AddCameraComponent)
  addCameraComponent: AddCameraComponent;

  @ViewChild(EditCameraComponent)
  editCameraComponent: EditCameraComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  constructor(
    private collectService: CollectDataService,
    private route: ActivatedRoute,
    public updateService: UpdateService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {

    this.idVpt = this.route.snapshot.params['id'];

    this.currentVideoRegistrator = new CameraModel(
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.collectService.getEquipmentData('camera')
      .subscribe(data => {
        this.cameraLists = data.list;
      });


  }

  activeAudit(camera: CameraModel) {
    console.log(camera);
    this.currentVideoRegistrator = camera;
  }

  show() {
    this.addCameraComponent.show(this.idVpt);
  }

  edit(camera) {
    this.currentVideoRegistrator = camera;
    this.editCameraComponent.show(this.currentVideoRegistrator);
  }

  update(event) {
    this.updateService.update(event, this.cameraLists, 'cameraId');
  }

  push(event) {
    this.updateService.dataPush(event, this.cameraLists);
  }



  deleteEquipment(camera) {
    this.currentVideoRegistrator = camera;
    this.delete.emit({
      equipmentId: this.currentVideoRegistrator.cameraId,
      equipmentName: 'camera',
      equipmentIdName: 'cameraId',
      equipmentLists: this.cameraLists
    });
  }




}
