import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {CollectDataService} from '../shared/services/collect-data.service';
import {ActivatedRoute} from '@angular/router';
import {UpdateService} from '../shared/services/update.service';

@Component({
  selector: 'app-full-info',
  templateUrl: './full-info.component.html',
  styleUrls: ['./full-info.component.css']
})
export class FullInfoComponent implements OnInit {

  index: number = 0;
  idVpt: string;

  constructor(
    public confirmationService: ConfirmationService,
    public collectService: CollectDataService,
    public route: ActivatedRoute,
    public updateService: UpdateService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.idVpt = this.route.snapshot.params['id'];
  }


  delete(event) {
    console.log(event);
    this.confirmationService.confirm({
      message: 'Вы действительно хотите удалить данную аудиторию ? ',
      accept: () => {
        this.collectService.deleteEquipment(event.equipmentName,  event.equipmentId)
          .subscribe(data => {
            const index = event.equipmentLists.findIndex(item => item[event.equipmentIdName] === event.equipmentId);
            event.equipmentLists.splice(index, 1);
            this.updateService.addSingle('Аудитория успешно удалена');
          }, error => {
            // this.updateService.addSingle(error.errorMsgRus, 'error');
          })
      },
      reject: () => { }
    });
  }

  onTabChange(event) {
    this.messageService.add({severity:'info', summary:'Tab Expanded', detail: 'Index: ' + event.index});
  }

}
