import {Injectable, OnInit} from '@angular/core';

import {SelectItem} from 'primeng/api';
import {ReferenceBooksService} from './reference-books.service';
import {catchError, map} from 'rxjs/operators';
import {Response} from '@angular/http';
import {EditModel} from '../model/edit.model';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Injectable()
export class ReferenceBooksRepository {

  katoIdList: SelectItem[] = [];
  schoolEduLangIdList: SelectItem[] = [];
  schoolOwnershipId: SelectItem[] = [];
  schoolDepartmentId: SelectItem[] = [];
  schoolLocationId: SelectItem[] = [];
  schoolCategoryId: SelectItem[] = [];
  schoolEduLevelId: SelectItem[] = [];
  schoolProfileId: SelectItem[] = [];
  schoolTypes: SelectItem[] = [];
  yesNo: SelectItem[];
  oblList: SelectItem[] = [];
  deliveryLists: SelectItem[] = [];
  foreignLang: SelectItem[] = [];
  ppentList: SelectItem[] = [];
  sprNetSpeed: SelectItem[] = [];

  constructor(
    private referenceBooksService: ReferenceBooksService,
    public langRepository: LangRepository
    ) {

    this.start();

    this.yesNo = [
      {
        label: 'Выберите статус',
        value: null
      },
      {
        label: 'Нет',
        value: 0
      },
      {
        label: 'Да',
        value: 1
      }
    ];

  }


  start() {
    // this.getEduLang();
    // this.getSchoolOwershipId();
    // this.getSchoolDepartmentId();
    // this.getSchoolLocationId();
    // this.getSchoolEduLevelId();
    // this.getSchoolProfileId();
    this.getSchoolTypes();
    this.getObl();
    this.getDelivery();
    // this.getForeignLang();
    // this.getPPENT();
    // this.getSprNetSpeed();
  }

  // getSprNetSpeed() {
  //   this.sprNetSpeed = [];
  //   this.referenceBooksService.getSprNetSpeed()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.sprNetSpeed);
  //     })
  // }

  // getEduLang() {
  //   this.schoolEduLangIdList = [];
  //   this.referenceBooksService.getSprEduLang()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolEduLangIdList);
  //     })
  // }

  // getSchoolOwershipId() {
  //   this.schoolOwnershipId = [];
  //   this.referenceBooksService.getSchoolOwnership()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolOwnershipId);
  //     }, error => {
  //     })
  // }

  // getSchoolDepartmentId() {
  //   this.schoolDepartmentId = [];
  //   this.referenceBooksService.getSchoolDepartament()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolDepartmentId);
  //     })
  // }

  // getSchoolLocationId() {
  //   this.schoolLocationId = [];
  //   this.referenceBooksService.getSchoolLocation()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolLocationId);
  //     })
  // }

  getSchoolCategory(schoolCategoryId: number) {

    this.schoolCategoryId = [];
    return this.referenceBooksService.getSchoolCategory(schoolCategoryId)
      .pipe(
        map((data: any) => {
          this.pushArray(data.items, this.schoolCategoryId);
          return data;
        }),
        catchError((error) => {

          throw(error.json())
        })
      );
  }

  // getSchoolEduLevelId() {
  //   this.schoolEduLevelId = [];
  //   return this.referenceBooksService.getSchoolEduLevel()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolEduLevelId);
  //     })
  // }

  // getSchoolProfileId() {
  //   this.schoolProfileId = [];
  //   this.referenceBooksService.getSprSchoolProfile()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.schoolProfileId);
  //     })
  // }

  getSchoolTypes() {
    this.schoolTypes = [];
    this.referenceBooksService.getSpravSchool()
      .subscribe(data => {
        this.pushArray(data.items, this.schoolTypes);
      })

  }

  getClassNumbers(schoolId: number) {
    const classNumberList: SelectItem[] = [];
    return this.referenceBooksService.getClassNoList(schoolId)
      .pipe(
        map(data => {
          data.items.forEach(item => {
            classNumberList.push({
              label: this.langRepository.langStatus === 1 ? item.nameKaz : item.nameRus,
              value: item.id,
            });
          });
          return classNumberList;
        }),
        catchError((error) => {
          throw(error.json())
        })
      );
  }

  getLang(schoolId: number) {
    const langList: SelectItem[] = [];
    return this.referenceBooksService.getClassLang(schoolId)
      .pipe(
        map(data => {
          data.items.forEach(item => {
            langList.push({
              label: this.langRepository.langStatus === 1 ? item.nameKaz : item.nameRus,
              value: item.id,
            });
          });
          return langList;
        }),
        catchError((error) => {
          throw(error.json())
        })
      );

  }


  getDirectionList(schoolId: number) {
    return this.referenceBooksService.getDirectionList(schoolId)
      .pipe(
        map(data => {
          return this.arrForEach(data.items);
        }),
        catchError((error) => {
          throw(error.json())
        })
      );
  }

  getLangByDirection(schoolId: number) {
    return this.referenceBooksService.getLangByDirection(schoolId)
      .pipe(
        map(data => {
          return this.arrForEach(data.items);
        }),
        catchError((error) => {
          throw(error.json())
        })
      );
  }


  arrForEach(data) {
     const arr: SelectItem[] = [];
     data.forEach(item => {
       arr.push({
         label: this.langRepository.langStatus === 1 ? item.nameKaz : item.nameRus,
         value: item.id,
       })
     });
     return arr;
  }

  getObl() {
    this.oblList = [];
    this.referenceBooksService.getObl()
      .subscribe(data => {
        this.pushArray(data.items, this.oblList);
      })
  }

  getRaion(oblId: number) {
    const oblList: SelectItem[] = [];
    this.referenceBooksService.getRaion(oblId)
      .subscribe(data => {
        this.pushArray(data.items, oblList);
      })
    return oblList;
  }


  getDelivery() {
    this.deliveryLists = [];
    this.referenceBooksService.getSprStudentDelivery()
      .subscribe(data => {
        this.pushArray(data.items, this.deliveryLists);
      })
  }


  getKato(oblId: number, raionId: number, parent: number = 0) {
    const katoList = [];
    return this.referenceBooksService.getKato(oblId, raionId, parent)
      .pipe(
        map(data => {
          data.items.forEach(item => {
            katoList.push({
              label: this.langRepository.langStatus === 1 ? item.nameKaz : item.nameRus,
              value: item.id,
              hasChildren: item.hasChildren
            });
          });
          return katoList;
        }),
        catchError((error) => {
          throw(error.json())
        })
      );
  }

  // getForeignLang() {
  //   this.foreignLang = [];
  //   this.referenceBooksService.getSprForeingLang()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.foreignLang);
  //     })
  // }

  // getPPENT() {
  //   this.ppentList = [];
  //   this.referenceBooksService.getPPENT()
  //     .subscribe(data => {
  //       this.pushArray(data.items, this.ppentList);
  //     })
  // }


  pushArray(data, arr) {
    data.forEach(item => {
      arr.push({
        label: this.langRepository.langStatus === 1 ? item.nameKaz : item.nameRus,
        value: item.id
      })
    })
  }




}









