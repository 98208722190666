import { Component, OnInit } from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {StatusModel} from '../shared/model/status.model';

@Component({
  selector: 'app-teh-status',
  templateUrl: './teh-status.component.html',
  styleUrls: ['./teh-status.component.css']
})
export class TehStatusComponent implements OnInit {

  techDataStatus: StatusModel[];

  constructor(
    private collectService: CollectDataService
  ) { }

  ngOnInit() {
    this.collectService.getTechDataStatus()
      .subscribe(data => {
        this.techDataStatus = data.data;
      })
  }


  editTechDataStatus(techDataStatus: StatusModel) {
    this.collectService.editTechDataStatus(techDataStatus.idVpt, techDataStatus.techDataStatus)
      .subscribe(data => {
        if (techDataStatus.techDataStatus === 1) {
          techDataStatus.techDataStatus = 0;
        } else {
          techDataStatus.techDataStatus = 1;
        }
      }, error => {

      })
  }

}
