export class MetalDetectorModel {
  constructor(
        public quantity: number = null, /*Количество оборудования*/
        public year: number = null, /*год производства*/
        public reactionDistance: number = null, /*дальность действия*/
        public metalDetectorTypeId:  string = null, /*тип металлоискателя*/
        public brandId:  string = null, /*Марка оборудования*/
        public manufacturerId: number = null, /*id производителя*/
        public evaluateId: number = null, /*id оценки*/
        public metalDetectorId: number = null, /*id оборудования*/
        public whyNotHaveReason: string = null, /*Причина отсутствия оборудования*/
        public whyNotHaveId: number = null
  ) { }
}
