import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from "../shared/services/collect-data.service";
import {UpdateService} from "../shared/services/update.service";
import {AddComputerComponent} from "./add-computer/add-computer.component";
import {ActivatedRoute} from "@angular/router";
import {EditComputerComponent} from "./edit-computer/edit-computer.component";
import {ComputerModel} from "../shared/model/computer.model";
import {CatalogRepository} from "../shared/services/catalog.repository";
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-computer',
  templateUrl: './computer.component.html',
  styleUrls: ['./computer.component.css']
})
export class ComputerComponent implements OnInit {

  idVpt: string;
  computerLists: any[] = [];
  currentComputer: ComputerModel;

  @ViewChild(AddComputerComponent)
  addComputerComponent: AddComputerComponent;

  @ViewChild(EditComputerComponent)
  editComputerComponent: EditComputerComponent;


  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public route: ActivatedRoute,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository

  ) { }



  ngOnInit() {

    this.idVpt = this.route.snapshot.params['id'];
    this.currentComputer = new ComputerModel();

    this.collectService.getEquipmentData('computer')
      .subscribe(data => {
        console.log(data);
        this.computerLists = data.list;
      }, error => {
        console.log(error)
      });
  }


  edit(computer) {
    this.currentComputer = computer;
    console.log(this.currentComputer)
    this.editComputerComponent.show(this.currentComputer, this.idVpt);
  }

  current(event) {
    this.currentComputer = event;
  }

  deleteEquipment(computer) {
    this.currentComputer = computer;
    this.delete.emit({
      equipmentId: this.currentComputer.computerId,
      equipmentName: 'computer',
      equipmentIdName: 'computerId',
      equipmentLists: this.computerLists
    });
  }

  add() {
    this.addComputerComponent.show(this.idVpt);
  }


  update(event) {
    console.log('sdsdsd')
    this.updateService.update(event, this.computerLists, 'computerId');
  }

  push(event) {
    this.updateService.dataPush(event, this.computerLists);
  }





}
