import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SchoolsService} from '../shared/services/schools.service';
import {PupilByDirectionModel} from '../shared/model/pupil-by-direction.model';
import {AddPupilDirectionComponent} from './add-pupil-direction/add-pupil-direction.component';
import {EditPupilDirectionComponent} from './edit-pupil-direction/edit-pupil-direction.component';
import {ReferenceBooksRepository} from '../shared/services/reference-books.repository';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-pupil-by-direction',
  templateUrl: './pupil-by-direction.component.html',
  styleUrls: ['./pupil-by-direction.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PupilByDirectionComponent implements OnInit {

  schoolId: number;
  pupilByDirectionList: PupilByDirectionModel[];
  currentPupilId: number;
  @ViewChild(AddPupilDirectionComponent)
  addPupilDirectionComponent: AddPupilDirectionComponent;

  @ViewChild(EditPupilDirectionComponent)
  editPupilDirectionComponent: EditPupilDirectionComponent;

  directionList: any[];
  classLang: any[];
  name: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private schoolService: SchoolsService,
    private referenceBooksRepository: ReferenceBooksRepository,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.name = this.route.snapshot.queryParams.name;
    this.schoolId = +this.route.snapshot.queryParams.schoolId;
    this.schoolService.getPupulByDirection(this.schoolId)
      .subscribe(data => {
        this.pupilByDirectionList = data.items;
      })

    this.start();

  }

  start(event?) {
    this.getLangByDirection();
    this.getDirectionList();
  }

  getLangByDirection() {
    this.classLang = [];
    this.referenceBooksRepository.getLangByDirection(this.schoolId)
      .subscribe(data => {
        this.classLang = data;
      })
  }

  getDirectionList() {
    this.directionList = [];
    this.referenceBooksRepository.getDirectionList(this.schoolId)
      .subscribe(data => {
        this.directionList = data;
      })
  }

  getPupulByDirection() {

  }




  addPupil() {
    this.addPupilDirectionComponent.show(this.schoolId);
  }

  editPupil(recordId: number) {
    this.editPupilDirectionComponent.show(this.schoolId, recordId);
  }

  pushTable(data) {
    this.pupilByDirectionList.push(data);
    this.addSingle(this.langRepository.wordList[148], 'success');
  }

  updateTable(data) {
    const find = this.pupilByDirectionList.find(item => data.id === item.id);

    for (let key in data) {
        find[key] = data[key];
    }

    this.addSingle(this.langRepository.wordList[128], 'success');
  }

  delete(recordId: number) {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[129],
      accept: () => {
        this.schoolService.deletePupilByDirectionCount(this.schoolId, recordId)
          .subscribe(data => {
            const findIndex = this.pupilByDirectionList.findIndex(item => item.id === recordId);
            this.pupilByDirectionList.splice(findIndex, 1);
            this.addSingle(this.langRepository.wordList[130], 'success');
          });
      }
    });
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }


  currentPupil(id: number) {
    this.currentPupilId = id;
  }

  back() {
    const schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'));
    this.router.navigate(['/schools'], { queryParams: { oblId: schoolInfo.oblId}});
  }


}
