import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../../shared/services/collect-data.service';
import {CatalogRepository} from '../../shared/services/catalog.repository';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GlushitelModel} from '../../shared/model/glushitel.model';
import {UpdateService} from '../../shared/services/update.service';
import {SelectItem} from "primeng/api";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-glushitel',
  templateUrl: './add-glushitel.component.html',
  styleUrls: ['./add-glushitel.component.css']
})
export class AddGlushitelComponent implements OnInit {
  cities1: SelectItem[];
  myForm: FormGroup;
  display: boolean = false;
  idVpt: string;
  selectedCity1: any;
  reasonStatus: boolean = false;

  glushitelStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();



  constructor(
    public catalogRepository: CatalogRepository,
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new GlushitelModel());
    this.cities1 = [
      {label:'Выберите причину', value: 0},
      {label:'Сумма закупа занесена в план МИО или ВУЗА', value: 2},
      {label:'Обявлен конкурс на закуп', value:3},
      {label:'Заключен договор с поставщиком', value:4},
      {label:'Не будет приобретено', value:5 },
      {label:'Другое', value:6}
    ];
  }

  count(value) {
    this.glushitelStatus = this.updateService.count(value);
  }

  pricina(value) {
    if (value === 6) {
      this.myForm.value === '';
    }
    this.selectedCity1 = value;
  }

  formValidation(data) {
    return new FormGroup(
      {
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        radius: new FormControl(data.radius, [Validators.required]),
        radiusActive: new FormControl(data.radiusActive, [Validators.required]),
        radiusTele2: new FormControl(data.radiusTele2, [Validators.required]),
        radiusBeeline: new FormControl(data.radiusBeeline, [Validators.required]),
        radiusAltel: new FormControl(data.radiusAltel, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        wifi: new FormControl(data.wifi, [Validators.required]),
        g3: new FormControl(data.g3, [Validators.required]),
        g4: new FormControl(data.g4, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required]),

      }
    )
  }


  show(idVpt: string) {
    this.idVpt = idVpt;
    this.display = true;
  }

  add() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }


    this.collectService.addEquipment('silencer', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }


  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }




}


