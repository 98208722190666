import {HttpModule} from '@angular/http';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CollectDataComponent} from './collect-data.component';
import {CollectDataRouting} from './collect-data-routing';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import { GlushitelComponent } from './glushitel/glushitel.component';
import {TopLineModule} from '../top-line/top-line.module';
import {MetalloiskatelComponent} from './metalloiskatel/metalloiskatel.component';
import {VideoRegistratorComponent} from './video-registrator/video-registrator.component';
import {ConditionerComponent} from './conditioner/conditioner.component';
import {CameraComponent} from './camera/camera.component';
import {TehStatusComponent} from './teh-status/teh-status.component';
import {CatalogManagementComponent} from './catalog-management/catalog-management.component';
import {FullInfoComponent} from './full-info/full-info.component';
import {EditGlushitelComponent} from './glushitel/edit-glushitel/edit-glushitel.component';
import {AddGlushitelComponent} from './glushitel/add-glushitel/add-glushitel.component';
import {AddConditionerComponent} from './conditioner/add-conditioner/add-conditioner.component';
import {AddVideoRegistratorComponent} from './video-registrator/add-video-registrator/add-video-registrator.component';
import {EditCameraComponent} from './camera/edit-camera/edit-camera.component';
import {EditConditionerComponent} from './conditioner/edit-conditioner/edit-conditioner.component';
import {EditVideoRegistratorComponent} from './video-registrator/edit-video-registrator/edit-video-registrator.component';
import {AddMetaloiskatelComponent} from './metalloiskatel/add-metaloiskatel/add-metaloiskatel.component';
import {EditMetaloiskatelComponent} from './metalloiskatel/edit-metaloiskatel/edit-metaloiskatel.component';
import {AddCameraComponent} from './camera/add-camera/add-camera.component';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService, ConfirmDialogModule, InputSwitchModule, InputTextModule} from 'primeng/primeng';
import {UpdateService} from './shared/services/update.service';
import {CatalogRepository} from './shared/services/catalog.repository';
import {CatalogService} from './shared/services/catalog.service';
import {CollectDataService} from './shared/services/collect-data.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {HttpClientModule} from '@angular/common/http';
import { PrinterComponent } from './printer/printer.component';
import { ComputerComponent } from './computer/computer.component';
import { ScannerComponent } from './scanner/scanner.component';
import { AddPrinterComponent } from './printer/add-printer/add-printer.component';
import { EditPrinterComponent } from './printer/edit-printer/edit-printer.component';
import { EditScannerComponent } from './scanner/edit-scanner/edit-scanner.component';
import { AddScannerComponent } from './scanner/add-scanner/add-scanner.component';
import { AddComputerComponent } from './computer/add-computer/add-computer.component';
import { EditComputerComponent } from './computer/edit-computer/edit-computer.component';
import { AddKeyComponent } from './add-key/add-key.component';
import { AddVideoComponent } from './add-key/add-video/add-video.component';
import { AddVideoRecordComponent } from './add-key/add-video-record/add-video-record.component';
import { AddHardwareKeyComponent } from './add-key/add-hardware-key/add-hardware-key.component';
import { EditKeyComponent } from './add-key/edit-key/edit-key.component';



@NgModule({
  declarations: [
    CollectDataComponent,
    GlushitelComponent,
    MetalloiskatelComponent,
    VideoRegistratorComponent,
    ConditionerComponent,
    CameraComponent,
    TehStatusComponent,
    CatalogManagementComponent,
    FullInfoComponent,
    EditGlushitelComponent,
    AddGlushitelComponent,
    EditMetaloiskatelComponent,
    AddMetaloiskatelComponent,
    EditVideoRegistratorComponent,
    EditConditionerComponent,
    EditCameraComponent,
    AddVideoRegistratorComponent,
    AddConditionerComponent,
    AddCameraComponent,
    FileUploadComponent,
    PrinterComponent,
    ComputerComponent,
    ScannerComponent,
    AddPrinterComponent,
    EditPrinterComponent,
    EditScannerComponent,
    AddScannerComponent,
    AddComputerComponent,
    EditComputerComponent,
    AddKeyComponent,
    AddVideoComponent,
    AddVideoRecordComponent,
    AddHardwareKeyComponent,
    EditKeyComponent,
  ],
  imports: [
    HttpModule,
    CollectDataRouting,
    TabViewModule,
    TableModule,
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    ConfirmDialogModule,
    ToastModule,
    TopLineModule,
    HttpClientModule,
    InputSwitchModule
  ],
  providers: [CollectDataService,
              CatalogService,
              CatalogRepository,
              UpdateService,
              ConfirmationService],
  bootstrap: []
})
export class CollectDataModule {

}
