import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CameraModel} from "../../shared/model/camera.model";
import {ComputerModel} from "../../shared/model/computer.model";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-computer',
  templateUrl: './edit-computer.component.html',
  styleUrls: ['./edit-computer.component.css']
})
export class EditComputerComponent implements OnInit {

  myForm: FormGroup;
  display: boolean = false;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter<any>();
  idVpt: string;

  constructor(
    public catalogRepository: CatalogRepository,
    public collectService: CollectDataService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new ComputerModel());
  }

  formValidation(data: ComputerModel) {
    return new FormGroup(
      {
        idVpt: new FormControl(data.idVpt, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        computerId: new FormControl(data.computerId, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required])
      }
    )
  }


  close() {
    this.myForm.reset();
    this.display = false;
  }


  edit() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.updateSilencerUnit('computer', this.myForm.value)
      .subscribe(data => {
        console.log(data)
        this.display = false;
        this.update.emit(data.equipmentDto);
      })
  }



  show(data, idVpt) {
    this.idVpt = idVpt;
    this.myForm = this.formValidation(data);

    this.collectService.getSingleEquipmentById('computer', data.computerId)
      .subscribe(data => {
        this.myForm = this.formValidation(data.data);
        this.display = true;
      })


  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }

}
