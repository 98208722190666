import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {SelectItem} from "primeng/api";
import {CatalogService} from "../../shared/services/catalog.service";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {UpdateService} from "../../shared/services/update.service";

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.css']
})
export class AddVideoComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  yesAndNo: SelectItem[];

  constructor(
    public catalogRepository: CatalogRepository,
    public collectDataService: CollectDataService,
    public updateService: UpdateService
  ) { }

  ngOnInit() {


    this.myForm = this.formValidation();
    this.yesAndNo = [
      {label: 'Есть', value: 1 },
      {label: 'Нет', value:  0}
    ];
  }


  show() {
    this.display = true;
  }




  formValidation() {
    return new FormGroup(
      {
        internetBroadcasting: new FormControl(null, [Validators.required]),
        replayOnComputer: new FormControl(null, [Validators.required])
      }
    )
  }


  save() {
    console.log(this.myForm.value)
  }


  add() {
    this.collectDataService.addVideoRecord(this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.myForm.reset();
        this.updateService.addSingle('Данные видеозаписи обновлены');
      }, error => {
        this.updateService.addSingle(error.errorMsgRus || 'Непредвиденная ошибка', 'error');
      })
  }

}
