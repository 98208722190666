import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule, ConfirmDialogModule, DropdownModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {TopLineComponent} from './top-line/top-line.component';
import {ToastModule} from "primeng/toast";
import {TopLineService} from "./shared/top-line.service";






@NgModule({
  declarations: [
    TopLineComponent,
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    ToastModule,
    ConfirmDialogModule
  ],
  providers: [TopLineService],
  exports: [TopLineComponent]
})


export class TopLineModule {

}
