import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {PrinterModel} from "../../shared/model/printer.model";
import {UpdateService} from "../../shared/services/update.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-printer',
  templateUrl: './add-printer.component.html',
  styleUrls: ['./add-printer.component.css']
})
export class AddPrinterComponent implements OnInit {

  myForm: FormGroup;
  display: boolean = false;
  printerStatus: boolean = false;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor(
    public catalogRepository: CatalogRepository,
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }



  ngOnInit() {
    this.myForm = this.formValidation(new PrinterModel())
  }


  add() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.addEquipment('printer', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  show() {
    this.display = true;
  }

  count(value) {
    this.printerStatus = this.updateService.count(value);
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }


  formValidation(data) {
    return new FormGroup(
      {
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required]),
      }
    )
  }
}
