export class SchoolModel {
  emit(ppentInfo: { ppent: any; oblId: any; raionId: any; }) {
    throw new Error("Method not implemented.");
  }

  constructor(
      public id: number = null, /*Код организации образования*/
      public katoCode: string = null, /*Код (КАТО)*/
      public bin: string = null, /*БИН*/
      public schoolTypeNameKaz: string = null, /*Тип организации образования на казахском*/
      public schoolTypeNameRus: string = null, /*Тип организации образования на русском*/
      public nameKaz: string = null, /*Полное наименование на казахском*/
      public nameRus: string = null, /*Полное наименование на русском*/
      public shortNameKaz: string = null, /*Сокращенное наименование на казахском*/
      public shortNameRus: string = null, /*Сокращенное наименование на русском*/
      public addressKaz: string = null, /*адрес на казахском*/
      public addressRus: string = null, /*адрес на русском*/
      public phoneNumber: string = null, /*Номер телефона школы*/
      public email: string = null, /*Адрес электронной почты школы*/
      public schoolOwnershipNameKaz: string = null, /*Форма собственности на казахском*/
      public schoolOwnershipNameRus: string = null, /*Форма собственности на русском*/
      public schoolDepartmentNameKaz: string = null, /*Ведомственная принадлежность на казахском*/
      public schoolDepartmentNameRus: string = null, /*Ведомственная принадлежность на русском*/
      public schoolLocationNameKaz: string = null, /*Территориальная принадлежность на казахском*/
      public schoolLocationNameRus: string = null, /*Территориальная принадлежность на русском*/
      public schoolCategoryNameKaz: string = null, /*Вид организации образования на казахском*/
      public schoolCategoryNameRus: string = null, /*Вид организации образования на казахском*/
      public schoolEduLevelNameKaz: string = null, /*Уровень образования на казахском*/
      public schoolEduLevelNameRus: string = null, /*Уровень образования на русском*/
      public schoolProfileNameKaz: string = null, /*Профиль организации образования на казахском*/
      public schoolProfileNameRus: string = null, /*Профиль организации образования на русском*/
      public isCorrectional: number = null, /*Является ли школой при исправительном учреждении (1-да,0-нет)*/
      public isIncomplete: number = null, /*Является ли малокомплектной школой (1-да,0-нет)*/
      public isSanatorium: number = null, /*Является ли санаторной школой (1-да,0-нет)*/
      public isMilitary: number = null, /*Является ли военной школой (1-да,0-нет)*/
      public isInternational: number = null, /*Является ли международной школой (1-да,0-нет)*/
      public totalCompCount: number = null, /*Количество компьютеров, используемых в учебно-воспитательном процессе*/
      public internetCompCount: number = null, /*из них, имеющих доступ в интернет*/
      public netSpeedNameKaz: string = null, /*Описание фактической скорости интернета на казахском*/
      public netSpeedNameRus: string = null, /*Описание фактической скорости интернета на русском*/
      public distanceToPpent: number = null, /*Расстояние до ППЕНТ в километрах*/
      public distanceToRegionCenter: number = null, /*Расстояние до областного центра в километрах*/
      public distanceToDistrictCenter: number = null, /*Расстояние до районного центра в километрах*/
      public studentDeliveryTimeNameKaz: string = null, /*Характеристика доставки на ЕНТ (на казахском)*/
      public studentDeliveryTimeNameRus: string = null, /*Характеристика доставки на ЕНТ (на русском)*/
      public adminFullName: string = null, /*Ф.И.О. ответственного лица организации образования*/
      public adminPhoneNumber: string = null, /*Контактные номера телефонов ответственного лица организации образования*/
      public schoolEduLangNameKaz: string = null, /*Языки обучения организации образования на казахском*/
      public schoolEduLangNameRus: string = null, /*Языки обучения организации образования на русском*/
      public schoolForeignLangNameKaz: string = null, /*Изучаемые иностранные языки в организации образования (на казахском)*/
      public schoolForeignLangNameRus: string = null, /*Изучаемые иностранные языки в организации образования (на русском)*/
      public adminPassword: string = null /*Пароль на вход для администратора школы*/
) {

}

}
