import { Injectable } from '@angular/core';
import {Lang} from './lang.model';
import {from} from 'rxjs';
import {Language} from '../../../shared/lang/lang.model';


@Injectable()
export class LangStaticDataSource {
  private Lang: Lang[] = [
    new Lang('Шығу','Выйти'),
    new Lang('Өзгерту', 'Редактировать'),
    new Lang('Жою', 'Удалить'),
    new Lang('Тілді таңдаңыз', 'Выберите язык'),
    new Lang('Аудиториялық тізім', 'Аудиторный список'),
    new Lang('Аудиторияны қосу','Добавить аудиторию'),
    new Lang('Орын жапсырмалары','Наклейки на места'),
    new Lang('Базаны жабу', 'Закрыть базу'),
    new Lang('Аудитория номірлері', 'Номер аудитории'),
    new Lang( 'Аудиторияның қазақша атауы','Наименование аудитории на казахском'),
    new Lang('Аудиторияның орысша атауы','Наименование аудитории на русском'),
    new Lang('Аудиториядағы орындар саны', 'Количество посадочных мест в аудитории'),
    new Lang('Аудитория орналасқан қабат','этаж, на котором расположена аудитория'),
    new Lang('Ғимараттың қазақша атауы', 'Наименование корпуса на казахском'),
    new Lang('Ғимараттың орысша атауы', 'Наименование корпуса на русском'),
    new Lang('Мекен-жайдың қазақша атауы', 'Наименование адреса на казахском'),
    new Lang('Мекен-жайдың орысша атауы', 'Наименование адреса на русском'),
    new Lang('Аудиториялар саны', 'Количество аудитории'),
    new Lang('Қабат', 'Этаж'),
    new Lang('Аудиторияны өзгерту', 'Радактировать аудиторию'),
    new Lang('Сақтау', 'Сохранить'),
    new Lang('Қайтару', 'Отменить'),
    new Lang('Аудиториялық фонд', 'Аудиторный фонд'),
    new Lang('Құрылым', 'Схема'),
    new Lang('Өзгерту', 'Редактировать'),
    new Lang('Қосу', 'Добавить'),
    new Lang('Жолды қосу','Добавить проход'),
    new Lang('Жолды өшіру', 'Убрать проход'),
    new Lang('Стол преподавателя', 'Стол преподавателя'), // Оқытушының үстелі
    new Lang('жол', 'проход'),
    new Lang('Басып шығару', 'Печать'),
    new Lang('Құрылымды өзгерту', 'Изменить схему'),
    new Lang('Құрылымды сақтау', 'Сохранить схему'),
    new Lang(' Аудиторияны өзгерту', 'Редактировать аудиторию'),
    new Lang('Деректер базасын жапқан соң аудиторияны өзгертуге, қосуға, өшіруге мүмкіндік болмайды', 'После закрытия базы данных редактировать, добавлять, удалять аудиторию будет невозможен'),
    new Lang('Суреттегі санды енгізіңіз', 'Введите число на картинке'),
    new Lang('Cанды енгізіңіз', 'Введите число'),
    new Lang('Сіз деректер базасын жапқыңыз келеді ме ?', 'Вы действительно хотите закрыть базу данных ?'),
    new Lang('Жол', 'Проход'),
    new Lang('Құру', 'Создать'),
    new Lang('Корпус', 'Корпус'), // Ғимарат
    new Lang('Адрес', 'Адрес'), // Мекен-жай
    new Lang('Название аудитории', 'Название аудитории'), // Аудиторияның атауы
    new Lang('Всего мест', 'Всего мест'), // Жалпы орындар
    new Lang('Басып шығарған күні', 'Дата распечатки'),
    new Lang('Жол', 'Проход'),
    new Language('Место на которой нельзя сажать тестируемого', 'Тестіленушіні отырғызуға болмайтын орын'),


  ];



  getLang() {
    return from([this.Lang]);
  }


}


