import {Http} from '@angular/http';
import {Injectable} from '@angular/core';
import {BrandNameModel} from '../model/brand-name.model';
import {BaseApi} from '../../../shared/core/base-api';

@Injectable()
export class CatalogService  extends  BaseApi {

  constructor(public http: Http) {
    super(http);
  }


  // Каталог названии оборудования.
  getTechEvaluates(type: string) {
    return this.get(`/api/nct/tech_data/api/catalog/${type}`);
  }

  // Получить каталоги для глушителя ППЕНТ.
  getStaticDataForSilencerUnit() {
    return this.get(`/api/nct/tech_data/api/getDropDownList/silencer`);
  }

  getStaticDataForUnitName() {
    return this.get(`/api/nct/tech_data/api/catalog/equipmentNames`);
  }

  // Каталог типов металлоискателя оборудования.
  getMetalDetectorTypes() {
    return this.get(`/api/nct/tech_data/api/catalog/metalDetectorTypes`);
  }

  recordingFormats() {
    return this.get(`/api/nct/tech_data/api/catalog/recordingFormats`);
  }

  playbackVideoStatuses() {
    return this.get(`/api/nct/tech_data/api/catalog/playbackVideoStatuses`);
  }

  getWhyNotHaveReason() {
    return this.get(`/api/nct/tech_data/api/catalog/whyNotHaveReasons`);
  }






}

