import { Injectable, OnInit} from '@angular/core';
import {AuditModel} from './shared/model/audit.model';
import {LangRepository} from './shared/lang/lang.repository';


@Injectable()
export class PrintRepository  {

  constructor(public langRepository: LangRepository ) {

  }


  print(table: HTMLElement, audit: AuditModel, ppentName: string = '') {

    let popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.write(`<html xmlns="http://www.w3.org/1999/html">
                                              <head>      
                                                  <style media="print" >                                                
                                                            @page { size: portrait; 
                                                                    margin: 0; 
                                                                    
                                                                   }
                                                            /* здесь будут стили для печати */
                                                            @media print {    
                                                                  .red, .yellow {
                                                                        -webkit-print-color-adjust: exact; 
                                                                        background-color: red;
                                                                        color: transparent;                                                                       
                                                                   }
                                                                   
                                                                   .wrapper{
                                                                        
                                                                        width: 100%;
                                                                        height: 96vh;
                                                                        size: auto; 
                                                                        margin: 0mm; 
                                                                        padding: 10px 15px;                                                                                                                                                                  
                                                               
                                                                    }
                                                                    
                                                                  .bottom {
                                                                    margin-top: 100px;
                                                                  }
                                                        
                                                                  .text-center, .teacherDesk {
                                                                    margin:0 auto;
                                                                  }
                                                                    
                                                                  .top p {
                                                                    border-bottom: 1px solid black;
                                                                    display: table;                                                              
                                                                  }
                                                              
                                                                  .teacherDesk {
                                                                    background: #D8D8D8;
                                                                    display: table;
                                                                    margin: 50px auto 15px auto;
                                                                    padding: 4px 5px;
                                                              
                                                                  }
                                                                  
                                                                  
                                                              
                                                                  table td, table th {
                                                                      border: 1px solid #000;
                                                                      font-size: 10px;
                                                                      padding: 5px;
                                                                  }
                                                              
                                                                  table {
                                                                    border-collapse: collapse;
                                                                    margin: 0 auto
                                                              
                                                                  }
                                                              
                                                                  .info p{
                                                                    margin: 5px;
                                                                  }
                                                                    
                                                                   .bottom .print:last-child .placePrint {
                                                                     bottom: -200%;
                                                                   } 
                                                                    
                                                                   .print {
                                                                    margin-top: 80px;
                                                                    position: relative;
                                                                    display: inline-block;
                                                                    margin-bottom: 30px;
                                                                   }
                                                                    
                                                                   .placePrint {
                                                                    position: absolute;
                                                                    right: -15px;
                                                                    bottom: -142%;
                                                                    border: 1px solid black;
                                                                    border-radius: 50%;
                                                                    display: flex;
                                                                    justify-content: center;
                                                                    align-items: center;
                                                                    padding: 20px;
                                                                   }
                                                                   
                                                                   img {
                                                                   display: none;
                                                                   }
                                                                   
                                                                   .audit-info {
                                                                      display: flex;
                                                                      justify-content: flex-start;\
                                                                      width: 80%;
                                                                   }
                                                                   
                                                                   .auditName {
                                                                     margin-top: 40px;
                                                                     display: flex;
                                                                     justify-content: space-between;
                                                                     width: 80%;
                                                                   }
                                                                   
                                                                   .signature, .fullname {
                                                                     position: relative;
                                                                   }
                                                                   
                                                                   .signature label, .fullname label {
                                                                     position: absolute;
                                                                     bottom: -20px;
                                                                     right: 30px;
                                                                   }
                                                                   
                                                                   .responsiblePerson {
                                                                     margin-right: 40px;
                                                                   }
                                                            }
                                                            
                                                      
                                                  </style>                           
                                                    
                                               </head>                                                  
                                               <body onload="window.print()">
                                                     <div class="wrapper">
                                                        <p>ППЕНТ/ВУЗ __ ${ ppentName }___</p>
                                                        <div class="audit-info">
                                                          <span>${this.langRepository.Lang[40]}  ${this.langRepository.langStatus == 1 ? audit.buildingNameKaz : audit.buildingNameRus}</span>
                                                          <span class="text-center">Аудитория № ${audit.num}</span>
                                                          <span>${this.langRepository.Lang[41]}: ${this.langRepository.langStatus == 1 ? audit.addressKaz : audit.addressRus}</span>
                                                        </div>   
                                                        <div class="auditName">                                    
                                                            <span>${this.langRepository.Lang[42]} ${this.langRepository.langStatus == 1 ? audit.nameKaz : audit.nameRus}</span>	
                                                            <span>${this.langRepository.Lang[43]} ${audit.placeCount}</span>
                                                         </div>                                         
                                                          
                                                              ${table.innerHTML} 
                                                                                                             
                                                        <div class="bottom">
                                                          <div class="print">
                                                              <div class="placePrint" style="padding: 10px 25px;">
                                                                  Печать<br> ППЕНТ/<br>приемной <br> комиссии
                                                              </div>                                                              
                                                              <p class="responsiblePerson">Ответственное лицо <br>  ППЕНТ/базового вуза: <span class="signature">      ___________________________<label class="fullname">(Ф.И.О)</label> </span> <span class="fullname">____________ <label >Подпись</label></span></p>
                                                          </div>                                                          
                                                          <div class="print">
                                                              <div class="placePrint">
                                                                  Печать<br> ВУЗ/РОО <br>(ГОО)
                                                              </div> 
                                                              <p>Руководитель ВУЗА / начальник отдела образования: <span class="signature">____________________________<label>(Ф.И.О)</label></span>  <span class="fullname"> _____________<label >Подпись</label></span></p>
                                                          </div>
                                                          
                                                        </div>	
	                                                    </div>
                                                   
                                               </body>
                                          </html>`);
    popupWinindow.document.close();



  }



}
