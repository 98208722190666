export class AuthService {

  private isAuthenticated = false;

  constructor() {
    if (sessionStorage.getItem('user')) {
      this.login();
    }
  }

  login() {
    this.isAuthenticated = true;
  }

  logout() {
    this.isAuthenticated = false;
    sessionStorage.clear();
  }


  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }

}
