import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {SelectItem} from "primeng/api";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {UpdateService} from "../../shared/services/update.service";
import {KeyModel} from "../../shared/model/key.model";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-hardware-key',
  templateUrl: './add-hardware-key.component.html',
  styleUrls: ['./add-hardware-key.component.css']
})
export class AddHardwareKeyComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  yesAndNo: SelectItem[];
  key: any;
  currentKey: any;
  @Output()
  updateKey: EventEmitter<KeyModel> = new EventEmitter();

  constructor(
    public catalogRepository: CatalogRepository,
    public collectDataService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation();


    this.yesAndNo = [
      {label: 'Есть', value: 1 },
      {label: 'Нет', value:  0}
    ];
  }




  show() {
    this.display = true;
  }


  formValidation() {
    return new FormGroup(
      {
        inumber: new FormControl(null, [Validators.required]),
        inOrg: new FormControl(null, [Validators.required]),
      }
    )
  }

  add() {
    this.collectDataService.addHardwareKey(this.myForm.value)
      .subscribe(data => {
        this.myForm.reset();
        this.display = false;
        this.updateService.addSingle('Аппаратный ключ обновлен');
        this.updateKey.emit(data.data);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  close() {
    this.display = false;
    this.myForm.reset();
  }




}
