import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {SchoolsService} from '../../shared/services/schools.service';
import {ReferenceBooksService} from '../../shared/services/reference-books.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {SchoolModel} from '../../shared/model/school.model';
import {FormValidationSchool} from '../../shared/core/form-validation';
import {EditModel} from '../../shared/model/edit.model';
import {ReferenceBooksRepository} from '../../shared/services/reference-books.repository';
import {ConfirmationService, MessageService} from 'primeng/api';
import {from, Observable} from 'rxjs';
import {reject} from 'q';
import {LangRepository} from '../../../shared/lang/lang.repository';
import { FullInfoComponent } from 'src/app/collect-data/full-info/full-info.component';

@Component({
  selector: 'app-edit-school',
  templateUrl: './edit-school.component.html',
  styleUrls: ['./edit-school.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class EditSchoolComponent extends FormValidationSchool implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  schoolOwnership: any[];
  katoList: any[] = [];
  raionList: any[] = [];
  schoolId: number;
  errorKato: boolean = true;
  schoolInfo: any;
  distanceToPpent: number;
  distanceToRegionCenter: number;
  distanceToDistrictCenter: number;
  studentDeliveryTimeId: any;
  correctional: number;

  @Input()
  fullInfo: SchoolModel;

  @Output()
  edit: EventEmitter<any> = new EventEmitter();


  constructor(private schoolsService: SchoolsService,
              private referenceBook: ReferenceBooksService,
              public referenceBooksRepository: ReferenceBooksRepository,
              private messageService: MessageService,
              public langRepository: LangRepository
              ) {
    super();
  }

  ngOnInit() {
    this.fullInfo = new SchoolModel();
    this.myForm = this.formValidation(new EditModel());
  }

  show(raionId, oblId, data) {
    this.katoList = [];
    this.referenceBooksRepository.start();
    this.schoolsService.getSingleSchoolData(oblId, raionId, data)
      .subscribe( data => {
        this.schoolInfo = data.data;
        this.schoolId = data.data.id;
        this.display = true;
        this.distanceToPpent = data.data.distanceToPpent;
        this.distanceToRegionCenter = data.data.distanceToRegionCenter;
        this.distanceToDistrictCenter = data.data.distanceToDistrictCenter;
        this.studentDeliveryTimeId = data.data.studentDeliveryTimeId;
        this.myForm.get('distanceToPpent').setValue(this.distanceToPpent);
        this.myForm.get('distanceToRegionCenter').setValue(this.distanceToRegionCenter);
        this.myForm.get('distanceToDistrictCenter').setValue(this.distanceToDistrictCenter);
        this.myForm.get('studentDeliveryTimeId').setValue(this.studentDeliveryTimeId);
        // this.referenceBook.getRaion(data.data.oblId)
        //   .subscribe( raionData => {
        //     this.raionList = this.pushArray(raionData.items);
        //     this.schoolCategory(data.data.schoolTypeId);
        //     this.myForm = this.formValidation(data.data);
        //     this.myForm.setControl('katoIdList', new FormArray(this.formArray(data.data.katoIdList)));
        //     this.getKato(oblId, raionId, data.data.katoIdList);
        //   });

        // if (!data.data.katoIdList.length) {
        //   return this.referenceBook.getKato(oblId, raionId)
        //     .subscribe(data => {
        //       const kato = [];
        //       kato.push(...this.pushArray(data.items));
        //       this.katoList.push(kato);
        //       this.myForm.setControl('katoIdList', new FormArray(this.formArray(null, null)));
        //     })
        // }
      });
  }

  schoolCategory(id: number) {
    this.referenceBooksRepository.getSchoolCategory(id)
      .subscribe(data => {})
  }

  getRaion(value, data?) {
    this.katoList = [];
    this.myForm.setControl('katoIdList', new FormArray(this.formArray([])));
    this.raionList = this.referenceBooksRepository.getRaion(value);
  }






  save() {

    this.schoolsService.editSchool(this.schoolId, this.myForm.value)
      .subscribe(data => {
        this.edit.emit(data.data);
        this.addSingle(this.langRepository.wordList[145]);
        this.reset();
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');

      })
  }

  getKato(oblId: number, raionId: number, katoList: any) {
      this.referenceBook.getKato(oblId, raionId)
        .subscribe(data => {
          this.katoList.push(this.pushArray(data.items));
          katoList.forEach(async(item) => {
           await this.referenceBook.getKato(oblId, raionId, item)
              .subscribe(data => {

                const kato = [];
                kato.push(...this.pushArray(data.items));
                this.katoList.push(kato);

              })
          })
        }, error => {

        });
  }

  getKatoParent(oblId, raionId, index, parent = 0) {
    if (index === null) {
      this.katoList = [];
      this.myForm.setControl('katoIdList', new FormArray(this.formArray([])));
    };
    this.referenceBooksRepository.getKato(oblId, raionId, parent)
      .subscribe(data => {
        if (this.katoList.length - 1 !== index || index == 0) {
          this.katoList.splice(index + 1, this.katoList.length - index + 1);
          const katoArr = this.myForm.value.katoIdList;
          katoArr.splice(index + 1, this.katoList.length - index + 1);
          if (!data.length) {
            this.myForm.setControl('katoIdList', new FormArray(this.formArray(katoArr)));
          } else {
            this.myForm.setControl('katoIdList', new FormArray(this.formArray(katoArr, null)));
          }
        }
        if (data.length) {
          this.katoList.push(data);
        };

      })

  }

  isCorrectional(status: number) {
    this.correctional = status;
  }

  test(value: number) {
    this.myForm.value.isCorrectional = value;

  }


  pushArray(items) {
    const katoList = [];
    items.forEach(item => {
      katoList.push({
        label: item.nameRus,
        value: item.id
      })
    })
    return katoList;
  }






  reset() {
    this.myForm.reset();
    this.display = false;
    this.katoList = [];
  }

  formArray(katoIdList?: any, items?) {
    const formArray = [];
    if (katoIdList) {
      for (let i = 0; i < katoIdList.length; i++) {
        formArray.push(new FormControl(katoIdList[i], [Validators.required]));
      };
    }

    if (items === null) {
      formArray.push(new FormControl(null, [Validators.required]));
    }
    return formArray;
  }


  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }



  formValidation(data?: EditModel) {
    return new FormGroup({
      // katoCode: new FormControl(data.katoCode, Validators.required),
      // oblId: new FormControl(data.oblId, [Validators.required]),
      // raionId: new FormControl(data.raionId, [Validators.required]),
      // katoIdList: new FormArray(data.katoIdList ? this.formArray(data.katoIdList) : [new FormControl(null)]),
      // bin: new FormControl(data.bin, [Validators.required]),
      // schoolTypeId: new FormControl(data.schoolTypeId, [Validators.required]),
      // nameKaz:  new FormControl(data.nameKaz, [Validators.required]),
      // nameRus:  new FormControl(data.nameRus, [Validators.required]),
      // shortNameKaz:  new FormControl(data.shortNameKaz, [Validators.required]),
      // shortNameRus:  new FormControl(data.shortNameRus, [Validators.required]),
      // addressKaz:  new FormControl(data.addressKaz, [Validators.required]),
      // addressRus:  new FormControl(data.addressRus, [Validators.required]),
      // phoneNumber:  new FormControl(data.phoneNumber, [Validators.required]),
      // email:  new FormControl(data.email, [Validators.required]),
      // schoolOwnershipId:  new FormControl(data.schoolOwnershipId, [Validators.required]),
      // schoolDepartmentId:  new FormControl(data.schoolDepartmentId, [Validators.required]),
      // schoolLocationId:  new FormControl(data.schoolLocationId, [Validators.required]),
      // schoolCategoryId:  new FormControl(data.schoolCategoryId, [Validators.required]),
      // schoolEduLevelId:  new FormControl(data.schoolEduLevelId, [Validators.required]),
      // schoolProfileId:  new FormControl(data.schoolProfileId, [Validators.required]),
      // isCorrectional:  new FormControl(data.isCorrectional, [Validators.required]),
      // isIncomplete:  new FormControl(data.isIncomplete, [Validators.required]),
      // isSanatorium:  new FormControl(data.isSanatorium, [Validators.required]),
      // isMilitary:  new FormControl(data.isMilitary, [Validators.required]),
      // isInternational:  new FormControl(data.isInternational, [Validators.required]),
      // totalCompCount:  new FormControl(data.totalCompCount, [Validators.required]),
      // internetCompCount:  new FormControl(data.internetCompCount, [Validators.required]),
      // netSpeedId:  new FormControl(data.netSpeedId, [Validators.required]),
      distanceToPpent:  new FormControl(data.distanceToPpent, [Validators.required]),
      distanceToRegionCenter:  new FormControl(data.distanceToRegionCenter, [Validators.required]),
      distanceToDistrictCenter:  new FormControl(data.distanceToDistrictCenter, [Validators.required]),
      studentDeliveryTimeId:  new FormControl(data.studentDeliveryTimeId, [Validators.required])
      // adminUserName:  new FormControl(data.adminUserName, [Validators.required]),
      // adminPhoneNumber:  new FormControl(data.adminPhoneNumber, [Validators.required]),
      // schoolEduLangIdList:  new FormControl(data.schoolEduLangIdList, [Validators.required]),
      // schoolForeignLangIdList:  new FormControl(data.schoolForeignLangIdList, [Validators.required])
    });
  }

  // getSchoolList(event) {
  //   console.log(event)
  // }
}

