import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {AuditModel} from '../shared/model/audit.model';
import {AuditService} from '../shared/services/audit.service';
import {ConfirmationService} from 'primeng/api';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-audit-lists',
  templateUrl: './audit-lists.component.html',
  styleUrls: ['./audit-lists.component.css']
})
export class AuditListsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output()
  currentAudit: EventEmitter<any> = new EventEmitter();

  auditList:  AuditModel[];
  displayedColumns: string[] = ['num', 'nameKaz', 'nameRus', 'addressKaz', 'addressRus', 'placeCount', 'buildingNameKaz', 'buildingNameRus', 'floor', 'hasScheme', 'edit', 'delete'];
  dataSource = new MatTableDataSource(this.auditList);
  activeAudit: number;

  constructor(
    private auditService: AuditService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.auditService.getAuditList()
      .subscribe(data => {
        this.auditList = data.items;
        this.updateTable();
      })


  }

  updateTable() {
    this.dataSource = new MatTableDataSource(this.auditList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }







  auditInfo(audit) {
    this.activeAudit = audit.num;
    this.currentAudit.emit(audit);
  }


  updateAudit(id: number, audit: any) {
    const find = this.auditList.find(item => item.id === id);
    for(let key in find) {
        find[key] = audit[key];
    }
  }

  deleteAudit(num: number) {
    const index = this.auditList.findIndex(item => item.num === num);
    console.log(index);
    this.auditList.splice(index, 1);
  }


}
