export class CameraModel {
  constructor(
      public cameraId: number = null,
      public idVpt: number = null,
      public quantity: number = null,
      public manufacturer: string = null,
      public year: number = null,
      public evaluateId: string = null,
      public brandId: number = null,
      public manufacturerId: number = null,
      public whyNotHaveReason: string = null,
      public whyNotHaveId: number = null
) {}
}
