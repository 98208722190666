export class KeyModel {
  constructor(
    public id: number = null,
    public idVpt: number = null,
    public inOrg: string = null,
    public inumber: string = null

) {

  }
}
