import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {BaseApi} from './core/base-api';




@Injectable()
export class AppService extends BaseApi {

  constructor(public http: Http) {
    super(http);
  }

  getPpent() {
    return this.getWithoutToken('api/ppent/login/ppent');
  }

  getUsersPpent(id: number) {
    return this.getWithoutToken(`api/ppent/login/ppent/${id}/users`);
  }


  getCurrentDataPpent() {
    return this.get(`api/ppent/me`);
  }

  saveDataPpent(data) {
    return this.put(`api/ppent/me`, data);
  }

  auth(data) {
    return this.postAuth(`api/ppent/login`, data);
  }



}
