import { Component, OnInit } from '@angular/core';
import {LangRepository} from "../../../shared/lang/lang.repository";
import {SchoolsService} from "../../shared/services/schools.service";
import {ConfirmationService, MessageService} from "primeng/api";


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  display: boolean = false;
  isBase: boolean = false;
  schoolReportList: any[] = [];
  collegeReportList: any[] = [];
  schoolPupilCountList: any[] = [];
  displaySidebar: boolean = false;

  constructor(
    public langRepository: LangRepository,
    public schoolsService: SchoolsService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    if (JSON.parse(sessionStorage.getItem('user')).isBase){
      this.isBase = true;
    }

    this.getReportFileList('school-list','schoolReportList');
    this.getReportFileList('college-list', 'collegeReportList');
    this.getReportFileList('school-pupil-count', 'schoolPupilCountList');


  }

  show() {
    this.display = true;
  }

  getReportFileList(type: string, listName: string) {
    this.schoolsService.getReportFileList(type)
      .subscribe(data => {
        this[listName] = data.items;
      })
  }


  confirmDelete(recordId: number, type: string, listName: string) {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[182],
      accept: () => {
        this.deleteReport(recordId, type, listName);
      }
    });
  }

  deleteReport(recordId: number, type: string, listName: string) {
    this.schoolsService.deleteReport(recordId, type)
      .subscribe(data => {

        let find = this[listName].findIndex(item => item.id === recordId);
        console.log(this[listName], find);
        this[listName].splice(find, 1);
        this.addSingle(this.langRepository.wordList[185], 'success');
      }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  report(type: string, format: string = 'pdf') {
    const myHeaders = JSON.parse(sessionStorage.getItem('user')).token;

    window.open(`${this.schoolsService.baseUrl}api/ppent/reports/${type}?token=${myHeaders}&lang=${this.langRepository.langStatus}&format=${format}`)
  }

  upload(files, type: string, list: string) {


    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);
    formData.append('filename', files[0].name);


    this.schoolsService.uploadReportFile(formData, type)
      .subscribe(data => {
        this[list].push(data.data);
        this.addSingle(this.langRepository.wordList[186], 'success')
      }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  dowload(reportId: number, type: string) {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`${this.schoolsService.baseUrl}api/ppent/reports/${type}/files/${reportId}?token=${token}`);
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }

}
