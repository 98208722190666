export class VideoRegistModel {
  constructor(
      public quantity: number = null, /*id оборудования*/
      public year: number = null, /*id vpt*/
      public capacity: number = null, /*Количество dvr*/
      public recordingTime: string = null, /*Марка dvr*/
      public blindArea: number = null, /*Страна производитель*/
      public onlineTranslation: number = null, /*формат записи*/
      public recordingFormatId: number = null, /*год производства*/
      public playbackVideoStatusId: number = null, /*Рабочее состояние*/
      public continuousRecordingTime: number = null, /*Продолжительность непрерывной записи*/
      public brandId: number = null, /*время записи*/
      public manufacturerId: number = null, /*объем записи*/
      public evaluateId: number = null, /*Возможность воспроизведения полученной записи на компьютере*/
      public dvrId: number = null, /*слепая зона*/
      public whyNotHaveReason: string = null, /*причина отсутствия оборудования*/
      public whyNotHaveId: number = null
    ) {}
}

