import {Component, OnInit} from '@angular/core';
import {CollectDataService} from './shared/services/collect-data.service';
import {UpdateService} from './shared/services/update.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {LangRepository} from '../shared/lang/lang.repository';

@Component({
  selector: 'app-collect',
  templateUrl: './collect-data.component.html',
  styleUrls: ['./collect-data.component.css']
})
export class CollectDataComponent implements  OnInit {

  index: number = 0;

  constructor(
    public confirmationService: ConfirmationService,
    public collectService: CollectDataService,
    public route: ActivatedRoute,
    public updateService: UpdateService,
    public messageService: MessageService,
    public router: Router,
    public langRepository: LangRepository
  ) { }


  ngOnInit() {
    this.index = +this.route.snapshot.queryParams.index;
  }


  delete(event) {
    this.confirmationService.confirm({
      message: 'Вы действительно хотите удалить данное оборудование ? ',
      accept: () => {
        this.collectService.deleteEquipment(event.equipmentName,  event.equipmentId)
          .subscribe(data => {
            const index = event.equipmentLists.findIndex(item => item[event.equipmentIdName] === event.equipmentId);
            event.equipmentLists.splice(index, 1);
            this.updateService.addSingle('Вы успешно удалили');
          }, error => {
            // this.updateService.addSingle(error.errorMsgRus, 'error');
          })
      },
      reject: () => { }
    });
  }

  onTabChange(event) {
    this.router.navigate(['/collect'], { queryParams: { index: event.index } });
  }



}
