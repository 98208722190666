export class AuditModel {
  constructor(
      public id: number  = null,
      public num: number  = null, /*Номер аудитории*/
      public nameKaz: string = null, /*Наименование аудитории на казахском*/
      public nameRus: string = null, /*Наименование аудитории на русском*/
      public addressKaz: string = null, /*Наименование аудитории на казахском*/
      public addressRus: string = null, /*Наименование аудитории на русском*/
      public placeCount: number = null, /*Количество посадочных мест в аудитории*/
      public buildingNameKaz: string = null, /*Наименование корпуса на казахском*/
      public buildingNameRus: string = null, /*Наименование корпуса на русском*/
      public silencerWhyNotHaveNameKaz: string = null, /*Причина отсутствия в аудитории глушителя 4G на казахском*/
      public silencerWhyNotHaveNameRus: string = null, /*Причина отсутствия в аудитории глушителя 4G на русском*/
      public floor: number = null, /*этаж, на котором расположена аудитория*/
      public isReserve: number = null, /*Данная аудитория будет использоваться в качестве резервной (0-нет, 1-да)*/
      public isSilencerExists: number = null, /*Имеется ли в данной аудитории глушитель 4G (0-нет, 1-да)*/
      public isCameraExists: number = null, /*Имеются ли в данной аудитории видеокамеры (0-нет, 1-да)*/
      public cameraWhyNotHaveNameKaz: number = null,
      public cameraWhyNotHaveNameRus: number = null,
      public isCanTransmitVideo: number = null, /*Могут ли видеокамеры в аудитории транслировать видео (0-нет, 1-да)*/
      public isBlindAreasExists: number = null, /*Имеются ли в данной аудитории слепые зоны для видеокамер (0-нет, 1-да)*/
      public isCanTransmitVideoByInternet: number = null, /*Могут ли видеокамеры в аудитории транслировать видео на сайт (0-нет, 1-да)*/
      public cameraWhyNotCanTransmitVideoByInternetNameKaz: number = null,
      public cameraWhyNotCanTransmitVideoByInternetNameRus: number = null,
      public airConditionSystemTypeNameKaz: string = null, /*Наименование вида системы вентиляции на казахском*/
      public airConditionSystemTypeNameRus: string = null, /*Наименование вида системы вентиляции на русском*/
      public toiletLocationTypeNameKaz: string = null, /*Описание расположения сан.узла на казахском*/
      public toiletLocationTypeNameRus: string = null, /*Описание расположения сан.узла на русском*/
      public hasScheme: number = null /*задана ли схема аудитории (0-нет, 1-да)*/
      ) { }
}
