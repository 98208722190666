import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SchoolsService} from '../../shared/services/schools.service';
import {ReferenceBooksRepository} from '../../shared/services/reference-books.repository';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-pupil-direction',
  templateUrl: './add-pupil-direction.component.html',
  styleUrls: ['./add-pupil-direction.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AddPupilDirectionComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  schoolId: number;

  @Input()
  classLang: any[];

  @Input()
  directionList: any[];

  @Output()
  addPupilData: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  update: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private schoolsService: SchoolsService,
    private referenceBooksRepository: ReferenceBooksRepository,
    private messageService: MessageService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
     this.myForm =  new FormGroup({
        studyDirectionId: new FormControl(null, [Validators.required]),
        langId: new FormControl(null, [Validators.required]),
        pupilCount: new FormControl(null, [Validators.required])
      });
    }

  show(schoolId: number) {
    this.display = true;
    this.schoolId = schoolId;
    this.update.emit();
  }

  addPupil() {
    this.schoolsService.addPupilByDirectionCount(this.schoolId, this.myForm.value)
      .subscribe(data => {
        this.addPupilData.emit(data.data);
        this.reset();
      }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  reset() {
    this.display = false;
    this.myForm.reset();
  }


  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }
}


