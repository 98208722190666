import {BaseApi} from '../../../shared/core/base-api';
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import {EditModel} from '../model/edit.model';
import {EditPupleModel} from '../model/edit-puple.model';
import {EditPupleDirectionModel} from '../model/edit-puple-direction.model';
import { SchoolModel } from '../model/school.model';



@Injectable()
export class SchoolsService extends  BaseApi {

  constructor(public http: Http) {
    super(http);
  }

  // Получение списка ППЕНТ для фильтра школ
  getPpentLists() {
    return this.get(`api/ppent/schools/ppent`);
  }

  getPpentOblList(ppentId: number) {
    return this.get(`api/ppent/schools/obl?ppent-id=${ppentId}`);
  }

  getRaionList(oblId: number, ppentId: number) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion?ppent-id=${ppentId}`);
  }

  getSchoolTypeList() {
    return this.get('api/ppent/schools/types');
  }

  getSchoolList(oblId: number, raionId: number, typeId: number, ppentId: number) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion/${raionId}/items?school-type-id=${typeId}&ppent-id=${ppentId}`);
  }

  getSingleSchoolData(oblId: number, raionId: number, schoolId: number) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion/${raionId}/items/${schoolId}`);
  }

  deleteSchool(schoolId: number) {
    return this.delete(`api/ppent/schools/items/${schoolId}`);
  }

  getPupilCountList(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/items`);
  }

  getSinglePupil(schoolId: number, recordId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/items/${recordId}`);
  }

  editPupil(schoolId: number, recordId: number, data: EditPupleModel) {
    return this.put(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/items/${recordId}`, data)
  }

  deletePupil(schoolId: number, recordId: number) {
    return this.delete(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/items/${recordId}`);
  }

  addPupil(schoolId, data) {
    return this.post(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/items`, data)
  }

  getPupulByDirection(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/items`);
  }

  // Добавление данных количества учеников по направлению, языку обучения
  addPupilByDirectionCount(schoolId: number, data) {
    return this.post(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/items`, data);
  }

  getSinglePupilByDirectionCount(schoolId: number, recordId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/items/${recordId}`);
  }

  // Получение списка областей
  getSchoolObl() {
    return this.get(`api/ppent/schools/obl`);
  }

  editDirectionPupil(schoolId: number, recordId: number, data: EditPupleDirectionModel) {
    return this.put(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/items/${recordId}`, data);
  }

  // Обновление из НОБД списка школ
  updateData(schoolId: number, data: SchoolModel, updatePupilCount: any) {
    return this.put(`api/ppent/schools/items/${schoolId}/update-from-nedb?update-pupil-count=${updatePupilCount}`, data);
  }

  deletePupilByDirectionCount(schoolId: number, recordId: number) {
    return this.delete(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/items/${recordId}`);
  }

  // Добавление организации образования
  addSchool(school: EditModel) {
    return this.post(`api/ppent/schools/items`, school);
  }

  editSchool(schoolId: number, data) {
    return this.put(`api/ppent/schools/items/${schoolId}`, data);
  }

  // Сервисы для работы с фильтром

  // Получение области
  getObl() {
    return this.get(`api/ppent/schools/obl`);
  }

  // Получение (ППЕНТ в области)
  getPpentInObl(oblId: string) {
    return this.get(`api/nct/schools/ppent?obl-id=${oblId}`);
  }



  getRaionObl(oblId: string) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion`);
  }

  // запрос списка школ
  getSchools(oblId: number, raionId, schoolTypeId: number) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion/${raionId}/items?school-type-id=${schoolTypeId}`)
  }

  closeSchoolDb(schoolId: number) {
    return this.post(`api/ppent/schools/items/${schoolId}/close`)
  }

  openSchoolDb(schoolId: number) {
    return this.post(`api/ppent/schools/items/${schoolId}/open`)
  }



  uploadReportFile(formData, type) {
    return this.post(`api/ppent/reports/${type}/files`, formData);
  }


  getReportFileList(type: string) {
    return this.get(`api/ppent/reports/${type}/files`);
  }

  deleteReport(reportId: number, type: string) {
    return this.delete(`api/ppent/reports/${type}/files/${reportId}`);
  }






}
