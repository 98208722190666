import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CatalogRepository} from '../../shared/services/catalog.repository';
import {CollectDataService} from '../../shared/services/collect-data.service';
import {UpdateService} from '../../shared/services/update.service';
import {CameraModel} from '../../shared/model/camera.model';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-camera',
  templateUrl: './add-camera.component.html',
  styleUrls: ['./add-camera.component.css']
})
export class AddCameraComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  idVpt: string;
  cameraStatus: boolean = false;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor(
    public catalogRepository: CatalogRepository,
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new CameraModel( ));
  }


  show(id: string) {
    this.display = true;
    this.idVpt = id;
  }

  add() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.addEquipment('camera', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }


  formValidation(data: CameraModel) {
    return new FormGroup(
      {
        idVpt: new FormControl(data.idVpt, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        cameraId: new FormControl(data.cameraId, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required])
      }
    )
  }

  reason(event) {
     if (event.value === 'Другое') {
       this.reasonStatus = true;
     } else {
       this.reasonStatus = false;
     }
  }

  otherReason(event) {
     this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }

  countCamera(count) {
    this.cameraStatus = this.updateService.count(+count);
  }

  reset() {
    this.myForm.reset();
  }

}
