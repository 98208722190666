import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AuthComponent} from './auth/auth.component';
import {AppRoutingModule} from './app-routing.module';
import { HttpModule } from '@angular/http';
import { AuthService} from './shared/auth.service';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { CheckInComponent } from './check-in/check-in.component';
import {AuditModule} from './audit/audit.module';

import {CollectDataModule} from './collect-data/collect-data.module';
import {SchoolsModule} from './schools/schools.module';
import {TopLineModule} from './top-line/top-line.module';
import {AuthGuard} from './shared/auth.guard';
import {AppService} from './shared/app.service';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {CalendarModule, DropdownModule, InputTextModule} from 'primeng/primeng';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    CheckInComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    AuditModule,
    CollectDataModule,
    SchoolsModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    CalendarModule,
    InputTextModule,
    TopLineModule

  ],
  providers: [AppService, AuthService, AuthGuard, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
