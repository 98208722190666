import {Injectable} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuditModel} from '../../../audit/shared/model/audit.model';
import {count} from "rxjs/operators";

@Injectable()
export class UpdateService {

  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) {

  }


  confirmDialog(text) {
    return new Promise(function(resolve, reject) {
      this.confirmationService.confirm({
        message: text,
        accept: () => {
          resolve();
        },
        reject: () => {
          reject();
        }

      });
    })



  }

  delete(id, lists) {
    const findId = lists.find(item => item.silencerId === id);
    lists.splice(findId, 0);
  }

  update(data, lists, type: string) {
    const find = lists.find(item => item[type] === data[type]);
    for (let key in data) {
      find[key] = data[key];
    }
    this.addSingle('Редактирование прошло успешно');
  }


  dataPush(data, lists) {
    console.log(data, lists);
    lists.push(data);
    this.addSingle('Оборудование успешно добавлена');
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }


  count(count: number) {
    if (count < 0 ) {
      this.addSingle('Количество не должно быть отрицательным', 'error');
      return false;
    } else if (count > 0) {
      return true;
    } else if (count === 0) {
      return false;
    }




  }



}
