import {Injectable} from '@angular/core';
import {Language} from './lang.model';
import {from} from 'rxjs';



@Injectable()
export class LangStaticDataSource {
    private Lang: Language[] = [
        new Language('Национальный центр тестирования', 'Ұлттық тестілеу орталығы'),
        new Language('Вход', 'Кіру'),
        new Language('Наименование ППЕНТ', 'ҰБТӨП атауы'),
        new Language('Текущий статус', 'Ағымдағы статусы'),
        new Language('Интерфейс ППЕНТ', 'ҰБТӨП интерфейсі'),
        new Language('Выберите интерфейс', 'Интерфейсті таңдаңыз'),
        new Language('Организации образования', 'Білім беру ұйымдары'),
        new Language('Наименование ППЕНТ на казахском', 'ҰБТӨП-тің қазақша атауы'),
        new Language('Наименование ППЕНТ на русском', 'ҰБТӨП-тің орысша атауы'),
        new Language('Сокращенное наименование на казахском', 'Қазақша қысқартылған атауы'),
        new Language('Сокращенное наименование на русском', 'Орысша қысқартылған атауы'),
        new Language('Наименование области', 'Облыс атауы'),
        new Language('Наименование района', 'Аудан атауы'),
        new Language('Адрес на казахском', 'Мекен-жайы қазақша'),
        new Language('Адрес на русском', 'Мекен-жайы орысша'),
        new Language('Дата рождения руководителя ППЕНТ', 'ҰБТӨП басшысының туған күні'),
        new Language('Номер домашнего телефона руководителя ППЕНТ', 'ҰБТӨП басшысының үй телефон нөмірі'),
        new Language('Номер рабочего телефона руководителя ППЕНТ', 'ҰБТӨП басшысының жұмыс телефонының нөмірі'),
        new Language('Номер мобильного телефона руководителя ППЕНТ', 'ҰБТӨП басшысының ұялы телефон нөмірі'),
        new Language('Дата рождения эксперта ППЕНТ', 'ҰБТӨП сарапшысының туған күні'),
        new Language('Номер домашнего телефона эксперта ППЕНТ', 'ҰБТӨП сарапшысының үй телефон нөмірі'),
        new Language('Номер рабочего телефона эксперта ППЕНТ', 'ҰБТӨП сарапшысының жұмыс телефон нөмірі'),
        new Language('Номер мобильного телефона эксперта ППЕНТ', 'ҰБТӨП сарапшысының ұялы телефон нөмірі'),
        new Language('Номер телефона ППЕНТ', 'ҰБТӨП телефон нөмірі'),
        new Language('Пароль', 'Құпия сөз'),
        new Language('Наименование базового ППЕНТ', 'Базалық ҰБТӨП-тің атауы'),
        new Language('Редактировать', 'Өзгерту'),
        new Language('Закрыть базу', 'Базаны жабу'),
        new Language('Открыть базу', 'Базаны ашу'),
        new Language('Адрес', 'Мекен жайы'),
        new Language('Количество посадочных мест', 'Орындар саны'),
        new Language('Наименование аудитории на казахском', 'Аудиторияның қазақша атауы'),
        new Language('Наименование аудитории на русском', 'Аудиторияның орысша атауы'),
        new Language('Наименование корпуса на казахском', 'Корпустың қазақша атауы '),
        new Language('Наименование корпуса на русском', 'Корпустың орысша атауы '),
        new Language('Адрес на казахском', 'Мекен жайы қазақша атауы '),
        new Language('Адрес на русском', 'Мекен жайы орысша атауы'),
        new Language('этаж', 'қабат'),
        new Language('Используется ли данная аудитория как резервная', 'Бұл аудитория резервтік ретінде пайдаланыла ма'),
        new Language('Имеется ли в данной аудитории глушитель 4G/5G', 'Бұл аудиторияда 4G/5G тұншықтырғышы бар ма'),
        new Language('Имеются ли в данной аудитории видеокамеры', 'Осы аудиторияда бейнекамералар бар ма'),
        new Language('Видеокамеры в аудитории могут транслировать видео', 'Аудиториядағы бейнекамералар бейне көрсетеді'),
        new Language('Имеются ли в данной аудитории слепые зоны для видеокамер', 'Осы аудиторияда бейнекамералар үшін соқыр аймақтар бар ма'),
        new Language('Видеокамеры в аудитории могут транслировать видео на сайт', 'Аудиториядағы бейнекамералар бейнені сайтқа таратады'),
        new Language('Наименование вида системы вентиляции', 'Желдету жүйесі түрінің атауы'),
        new Language('Описание расположения сан.узла', 'Санитарлық торап орналасуының сипаттамасы'),
        new Language('задана ли схема аудитории', 'аудиторияның схемасы қойылды ма'),
        new Language('Нарисуйте схему 1 Аудитории', '1 аудиторияның сызбасын сызыңы'),
        new Language('у вас в аудитории 120 мест', 'сізде аудиторияда 120 орын бар'),
        new Language('Сохранить', 'Сақтау'),
        new Language('Вернуться', 'Қайту'),
        new Language('Редактирование прошло успешно!', 'Редакциялау сәтті өтті!'),
        new Language('Вы действительно хотите удалить аудиторию?', 'Сіз шынымен аудиторияны жойғыңыз келе ме?'),
        new Language('Добавить', 'Қосу'),
        new Language('Выберите область', 'Облысты таңдаңыз'),
        new Language('Выберите ППЕНТ', 'ҰБТӨП-ті таңдаңыз'),
        new Language('Область/район школы', 'Мектептің  облысы/ауданы'),
        new Language('Выберите область', 'Облысты таңдаңыз'),
        new Language('Выберите район', 'Ауданды таңдаңыз'),
        new Language('Выберите тип', 'Түрді таңдаңыз'),
        new Language('БИН', 'БСН'),
        new Language('Количество учеников по классам', 'Сыныптар бойынша оқушылар саны'),
        new Language('Количество учеников по направлениям', 'Бағыттар бойынша оқушылар саны'),
        new Language('Код КАТО', 'ӘАОЖ Коды'),
        new Language('Местоположение (КАТО) на русском', 'Орналасқан жері (ӘАОЖ) орыс тілінде'),
        new Language('Полное наименование на казахском', 'Қазақша толық атауы'),
        new Language('Полное наименование на русском', 'Орысша толық атауы'),
        new Language('тип организации образования', 'Білім беру ұйымының типі'),
        new Language('Номер телефона школы:', 'Мектептің телефон нөмірі:'),
        new Language('Адрес электронной почты школы:', 'Мектептің электрондық поштасы:'),
        new Language('Форма собственности:', 'Меншік нысаны:'),
        new Language('Ведомственная принадлежность:', 'Ведомстволық тиістілігі:'),
        new Language('Территориальная принадлежность:', 'Территориялық тиістілігі:'),
        new Language('Вид организации образования:', 'Білім беру ұйымының түрі:'),
        new Language('Уровень образования:', 'Білім беру деңгейі:'),
        new Language('Профиль обучения:', 'Оқыту бейіні:'),
        new Language('Является ли школой при исправительном учреждении:', 'Түзету мекемесі жанындағы мектеп болып табыла ма:'),
        new Language('Является ли малокомплектной школой:', 'Шағын жинақты мектеп болып табыла ма:'),
        new Language('Является ли санаторной школой:', 'Санаторлық мектеп болып табыла ма:'),
        new Language('Является ли военной школой:', 'Әскери мектеп болып табыла ма:'),
        new Language('Является ли международной школой:', 'Халықаралық мектеп болып табыла ма:'),
        new Language('Количество компьютеров, используемых в учебно-воспитательном процессе:', 'Оқу-тәрбие процесінде пайдаланылатын компьютерлер саны:'),
        new Language('из них, имеющих доступ в интернет:', 'оның ішінде, интернет желісіне қосылғаны:'),
        new Language('Описание фактической скорости интернета:', 'Интернеттің нақты жылдамдығын сипаттау:'),
        new Language('Расстояние до ППЕНТ в километрах:', 'ҰБТӨП-ке дейінгі қашықтық километрмен:'),
        new Language('Расстояние до областного центра в километрах:', 'Облыс орталығына дейінгі қашықтық километрмен:'),
        new Language('Расстояние до районного центра в километрах:', 'Аудан орталығына дейінгі қашықтық километрмен:'),
        new Language('Время доставки тестируемых:', 'ҰБТ-ға жеткізілу уақыты:'),
        new Language('Ф.И.О. ответственного лица организации образования:', 'Білім беру ұйымының жауапты тұлғасының Т.А.Ә.'),
        new Language('Контактные номера телефонов ответственного лица организации образования:', 'Білім беру ұйымы жауапты тұлғасының байланыс телефондары:'),
        new Language('Языки обучения организации образования:', 'Білім беру ұйымының оқыту тілдері:'),
        new Language('Изучаемые иностранные языки в организации образования:', 'Білім беру ұйымдарында оқытылатын шетел тілдері:'),
        new Language('Пароль для входа:', 'Кіру үшін құпия сөз'),
        new Language('Вы действительно хотите удалить данную организацию образования?', 'Сіз бұл білім беру ұйымын шынымен жойғыңыз келе ме?'),
        new Language('Классы', 'Сыныптар'),
        new Language('Язык обучения', 'Оқыту тілі'),
        new Language('Количество учеников', 'Оқушылардың саны'),
        new Language('Удалить', 'Жою'),
        new Language('Направление обучения', 'Оқыту бағыты'),
        new Language('язык обучения', 'Оқыту тілі'),
        new Language('Количество учеников', 'Оқушылардың саны'),
        new Language('Аудиторный фонд', 'Аудиторлық қор'),
        new Language('Полное наименование', 'Толық атауы'),
        new Language('ППЕНТ', 'ҰБТӨП'),
        new Language('Тип', 'Түрі'),
        new Language('Выберите язык', 'Тілді таңдаңыз'),
        new Language('Открыть базу', 'Базаны ашу'),
        new Language('Закрыть базу', 'Базаны жабу'),
        new Language('Схема аудитории', 'Аудиторияның схемасы'),
        new Language('Добавить аудиторию', 'Аудиторияны қосу'),
        new Language('Наименование', 'Атауы'),
        new Language('Да', 'Иә'),
        new Language('Нет', 'Жоқ'),
        new Language('Номер класса', 'Сынып нөмірі'),
        new Language('Искать', 'Іздеу'),
        new Language('Номер аудитории', 'Аудитория нөмірі'),
        new Language('Отменить', 'Болдырмау'),
        new Language('Выберите местоположение по КАТО', 'ЭТОЖ бойынша орналасқан жері'),
        new Language('район школы', 'Мектептің  облысы'),
        new Language('Технические средства', 'Техникалық құрылғылар'),
        new Language('Аудитория успешно добавлена', 'Аудитория сәтті қосылды'),
        new Language('аудитория успешно удалена', 'Аудитория сәтті өшірілді'),
        new Language('Заполните форму', 'Форманы толтырыңыз'),
        new Language('Войти', 'Кіру'),
        new Language('Список пользователей', 'Пайдаланушылар тізімі'),
        new Language('Выберите пользователя', 'Пайдаланушыны таңдаңыз'),
        new Language('Список ППЕНТ', 'ҰБТӨП тізімі'),
        new Language('Выберите ППЕНТ', 'ҰБТӨП таңдаңыз'),
        new Language('Успешно изменен', 'Cәтті өзгертілді'),
        new Language('Вы действительно хотите удалить данное направление', 'Сіз шынымен бұл бағытты жойғыңыз келе ме?'),
        new Language('Успешно удален', 'Сәтті өшірілді?'),
        new Language('Добавлен', 'Қосылды'),
        new Language('Номер класса', 'Сынып нөмірі'),
        new Language('Класс изменен', 'Сынып өзгертілді'),
        new Language('Добавлен новый класс', 'Жаңа сынып қосылды'),
        new Language('Вы действительно хотите удалить данные', 'Сіз шынымен бұл деректерді өшергіңіз келеді ме?'),
        new Language('Класс успешно удален', 'Сынып сәтті өшірілді'),
        new Language('Школа успешно добавлена', 'Мектеп сәтті қосылды'),
        new Language('Организация образования удалена', 'Білім беру ұйымы өшірілді'),
        new Language('Организация образования успешно обновлена', 'Білім беру ұйымы сәтті өзгертілді'),
        new Language('Выберите номер', 'Нөмерді таңдаңыз'),
        new Language('Схема успешно сохранена', 'Сұлба сәтті сақталды'),
        new Language('Введите пароль','Құпиясөзді еңгізіңіз'),
        new Language('Номер аудитории', 'Аудитория нөмірі'),
        new Language('Вы действительно хотите удалить данную аудиторию', 'Сіз шынымен бұл аудиторияны өшіргіңіз келеді ме ?'),
        new Language('Школа успешно обновлена', 'Мектеп сәтті өзгертілді?'),
        new Language('Вы действительно хотите удалить данную школу?', 'Cіз шынымен осы мектіпті өшіргіңіз келеді ме?'),
        new Language('Школа удалена', 'Мектеп өшірілді'),
        new Language('Успешно добавлен', 'Сәтті қосылды'),
        new Language('Область', 'Облыс'),
        new Language('Статус', 'Мәртебесі'),
        new Language('Место на которой нельзя сажать тестируемого', 'Тестіленушіні отырғызуға болмайтын орын'),
        new Language('Ф.И.О. руководителя ППЕНТ', 'ҰБТӨП басшысының Аты-жөні'),
        new Language('Ф.И.О. эксперта ППЕНТ', 'ҰБТӨП сарапшысының Аты-жөні'),
        new Language('Адрес ППЕНТ на казахском', 'ҰБТӨП-тің қазақша мекен-жайы'),
        new Language('Адрес ППЕНТ на русском', 'ҰБТӨП-тің орысша мекен-жайы'),
        new Language('Код базового ППЕНТ', 'Базалық ҰБТӨП-тің коды'),
        new Language('Информация о ППЕНТ', 'ППЕНТ-і туралы ақпарат'),
        new Language('Количество учеников по направлениям, языкам обучения', 'Количество учеников по направлениям, языкам обучения'),
        new Language('Выход', 'Шығу'),
        new Language('Авторизация', 'Авторизациялау'),
        new Language('Редактировать данные ППЕНТ', 'ППЕНТ деректерін өзгерту'),
        new Language('Текущие данные вашего ППЕНТ', 'ҰБТӨП-тің ағымдағы деректері'),
        new Language('Электронная почта ППЕНТ', 'ҰБТӨП электронды поштасы'),
        new Language('Электронная почта ППЕНТ', 'ҰБТӨП электронды поштасы'),
        new Language('Открыть/Закрыть', 'Ашық/Жабық'),
        new Language('База данных успешно открыта', 'Деректер базасы сәтті ашылды'),
        new Language('База данных успешно закрыта', 'Деректер базасы сәтті жабылды'),
        new Language('База данных успешно закрыта', 'Деректер базасы сәтті жабылды'),
        new Language('Вы действительно хотите открыть базу данной организации образования?', 'Cіз шынымен деректер базасын ашқыныз келеді ме ?'),
        new Language('Вы действительно хотите закрыть базу данной организации образования?', 'Cіз шынымен деректер базасын жапқыныз келеді ме ?'),
        new Language('Район', 'Аудан'),
        new Language('Открыть', 'Ашу'),
        new Language('Закрыть', 'Жабу'),
        new Language('Закрыть базу данных', 'Деректер базасын жабу'),
        new Language('Вы действительно хотите закрыть базу данных ?', 'Сіз шынымен деректер базасын жапқыныз келеді ме ?'),
        new Language('Причина отсутствия', 'Жоқ себебі'),
        new Language('Справочник организаций среднего образования', 'Орта білім беру ұйымдарының анықтамалығы'),
        new Language('Справочник организации ТиПО', 'ТжКБ ұйымдары анықтамалығы'),
        new Language('Справочник по контингенту учащихся организации образования', 'Білім беру ұйымдары оқушыларының контингенті бойынша анықтамалық'),
        new Language('Скачать', 'Жүктеу'),
        new Language('Данные организаций образования в Excel', ' Excel-дегі білім беру ұйымдарының мәліметтері'),
        new Language('Вы действительно хотите удалить этот файл?', 'Осы файлды шын мәнінде жойғыңыз келе ме?'),
        new Language('Отчеты', 'Есептер'),
        new Language('Загрузить файл', 'Загрузить файл'),
        new Language('Файл успешно удален', 'Файл сәтті жойылды'),
        new Language('Файл успешно загружен', 'Файл сәтті жүктелді'),
        new Language('Причина невозможности транслировать видео на сайт', 'Причина невозможности транслировать видео на сайт'),
        new Language('Код вуза', 'ЖОО коды'),
        new Language('Название ППЕНТ', 'ҰБТӨП атауы'),
        new Language( 'Состояния оборудования', 'Құрал-жабдықтың жай-күйі'),
        new Language('Подробнее', 'Толығырақ'),
        new Language('Закрыта', 'Жабық'),
        new Language('Перейти', 'Көшу'),
        new Language('Перейти', 'Көшу'),
        new Language('Искать', 'Іздеу'),
        new Language('Глушитель', 'Ұялы байланыс желілерін түншықтырғыш құрал'),
        new Language('Металлоискатель', 'Металл іздегіш' ),
        new Language('Добавить металлоискатель', 'Металл іздегіш қосу'),
        new Language('Количество металлоискателя:', 'Металл іздегіштің саны:'),
        new Language('Марка металлоискателя:', 'Металл іздегіштің маркасы:'),
        new Language('Видеорегистратор', 'Бейнетіркегіш'),
        new Language('Кондиционер', 'Ауа баптағыш'),
        new Language('Добавить кондиционер', 'Ауа баптағыш қосу' ),
        new Language('Колличество кондиционера', 'Ауа баптағыштың саны'),
        new Language('Год выпуска', 'Шығарылған жылы'),
        new Language('Причина отсутствия кондинционера (Если отсутствует)', 'Ауа баптағыштың болмау себебі (егер жоқ болған жағдайда)'),
        new Language('Камера', 'Бейнебақылау'),
        new Language('Добавить камеру', 'Бейнебақылауды қосу'),
        new Language('Количество камеры', 'Бейнебақылау саны'),
        new Language('Марка камеры', 'Бейнебақылау маркасы'),
        new Language('Причина отсутствия камеры (Если отсутствует)', 'Бейнебақылау болмау себебі (егер жоқ болған жағдайда)'),
        new Language('Добавить принтер', 'Принтер қосу'),
        new Language('Причина отсутствия принтера (Если отсутствует)', 'Принтердің болмау себебі (егер жоқ болған жағдайда)'),
        new Language('Ауа баптағыш қосу', 'Причина отсутствия принтера (Если отсутствует)'),
        new Language('Добавить техническое средство', 'Техникалық құралды қосу'),
        new Language('Добавить глушитель', 'Ұялы байланыс желілерін тұншықтырғыш құралды қосу'),
        new Language('Количество глушителя', 'Ұялы байланыс желілерін тұншықтырғыш құралдың саны'),
        new Language('Марка глушителя', 'Ұялы байланыс желілерін тұншықтырғыш құралдың маркасы'),
        new Language('Страна производитель', 'Өндіруші ел'),
        new Language('Год выпуска:', 'Шығарылған жылы:'),
        new Language('Рабочее состояние', 'Жұмыс жағдайы'),
        new Language('Количество', 'Саны' ),
        new Language('Радиус действия', 'Әрекет ету радиусы'),
        new Language('актив радиус действия', 'әрекет ету  радиусы активі'),
        new Language( 'теле2 радиус действия', 'Теле 2 әрекет ету  радиусы'),
        new Language('билайн радиус действия', 'Билайн әрекет ету  радиусы'),
        new Language('алтел радиус действия (М)', 'Алтел әрекет ету  радиусы'),
        new Language('WI-FI радиус действия (м)', 'WI-FI  әрекет ету  радиусы'),
        new Language('4G радиус действия (м)', '4G әрекет ету  радиусы'),
        new Language('3G радиус действия', '3G әрекет ету  радиусы'),
        new Language('Название файла', 'Файлдың атауы'),
        new Language('Скачать файл', 'Файлды жүктеу'),
        new Language('Скачать', 'Жүктеу'),
        new Language('Аппаратный ключ', 'Аппараттық кілт'),
        new Language('Подробная информация', 'Толық ақпарат'),
        new Language( 'Файлы', 'Файлдар'),
        new Language('Название файла Скачать', 'Файлдың атауын көшіру'),
        new Language('Редактировать аппаратный ключ', 'Аппараттық кілтті түзету'),
        new Language('инвентарный номер', 'Инвентарлық нөмірі'),
        new Language('организация:', 'Ұйым:'),
        new Language('Добавить техническое средство', 'Техникалық құралдарды қосу'),
        new Language('добавить оборудования', 'Құрал-жабдықтарды қосу'),
        new Language('добавить формат видеозаписи', 'Бейнежазбаның форматын қосу'),
        new Language( 'добавить компьютер', 'Компьтерді қосу'),
        new Language('количество компьютера', 'Компьютерлер саны'),
        new Language('год выпуска', 'Шыққан жылы'),
        new Language('рабочее состояние', 'Жұмыс жағдайы'),
        new Language('хорошее', 'Жақсы'),
        new Language('причина отсутствия оборудования', 'Құрал-жабдықтың болмау себебі'),
        new Language('Редактировать', 'Түзету'),
        new Language('Если отсутствует', 'Болмаған жағдайда'),
        new Language('Сканер саны', 'Количество сканера'),
        new Language('причина нерабочего состояния', 'Жұмыс жасамауының себебі'),
        new Language('если не работает', 'Жұмыс жасамаған жағдайда'),
        new Language('Загрузить файлы СЭС', 'СЭС файлдарын жүктеу'),
        new Language('Сформировать отчет', 'Есепті қалыптастыру'),
        new Language('Скачать отчет', 'Есепті жүктеу'),
        new Language('Загрузить файлы отчета', 'Есеп файлдарын жүктеу'),
        new Language('Файлы для скачивания', 'Жүктеу файлдары'),
        new Language('Добавить сканер', 'Сканерді қосу'),
        new Language('причина нерабочего состояние (Если не работает)', 'жұмыстан тыс жағдайдың себебі (егер жұмыс істемесе)'),
        new Language('причина отсутствия оборудования (Если отсутствует)', 'жабдықтың болмау себебі (егер жоқ болса)'),
        new Language('Редактировать сканер', 'Сканерді өңдеу'),
        new Language('Введите причину нерабочего состояние (Обязательно)', 'Жұмыс істемейтін жағдайдың себебін келтіріңіз (міндетті түрде)'),
        new Language('введите причину отсутствия (Обязательно)', 'болмау себебін келтіріңіз (міндетті түрде)'),
        new Language('Добавить сканер', 'Сканерді қосу'),
        new Language('введите причину нерабочего состояние (Обязательно)', 'жұмыс істемейтін жағдайдың себебін келтіріңіз (міндетті түрде)'),
        new Language('Редактировать принтер', 'Принтерді өңдеу'),
        new Language('Редактировать компьютер', 'Компьютерді өңдеу'),
        new Language('Марка оборудования', 'Жабдықтың маркасы'),
        new Language('Страна', 'Елі'),
        new Language('Редактировать камеру', 'Камераны өңдеу'),
        new Language('Количество кондиционера', 'Кондиционер саны'),
        new Language('Редактировать данные кондиционера', 'Кондиционер деректерін өңдеу'),
        new Language('Добавить кондиционер', 'Кондиционерді қосу'),
        new Language('Количество видерегистраторов', 'Бейнетіркеуіштер саны'),
        new Language('Формат записи', 'Жазу форматы'),
        new Language('Продолжительность непрерывной записи', 'Үздіксіз жазу ұзақтығы'),
        new Language('Редактировать глушитель', 'Ұялы байланыс желілерін тұншықтырғыш құралын өңдеу'),
        new Language('Реагирование на наличие средств связи, посторонних предметов с расстояния (см):', 'Қашықтықтан байланыс құралдарының, бөгде заттардың болуына ден қою (см):'),
        new Language('Тип металлоискателя', 'Металіздегіштің түрі'),
        new Language('Возможность воспроизведения полученной записи на компьютере', 'Алынған жазбаны компьютерде көру мүмкіндігі'),
        new Language('Марка видеорегистратора', 'Бейнетіркеуіш маркасы'),
        new Language('Рабочее состояние видеорегистратора', 'Бейнетіркеуіш жағдайының жұмысы'),
        new Language('Причина отсутствия видеорегистратора', 'Бейнетіркеуіш болмауының себебі'),
        new Language('Добавить видеорегистратор', 'Бейнетіркеуішті қосу'),
        new Language('Продолжительность непрерывной записи (Максимум 720 минут)', 'Үздіксіз жазу ұзақтығы (максимум 720 минут)'),
        new Language('Редактировать видеорегистратор', 'Бейнетіркеуішті өңдеу'),
        new Language('Марка', 'Маркасы'),
        new Language('Марка производителя', 'Өңдіруші маркасы'),
        new Language('Количество принтеров', 'Принтерді өңдеу'),
        new Language('Добавить аппаратный ключ', 'Аппараттық кілтті енгізу'),
        new Language('На балансе', 'балансында'),
        new Language('Обновить данные аппаратного ключа', 'Аппараттық кілттің деректерін жаңарту'),
        new Language('Рабочее состояние металлоискателя', 'Металіздегіштің жұмыс жағдайы'),
        new Language('Загрузка файлов', 'Файлдарды жүктеу'),
        new Language('Текущие данные Аппаратного ключа', 'Принтерді өңдеу'),
        new Language('Редактировать принтер', 'Аппараттық кілттің ағымдағы деректері'),
        new Language('радиус действия глушителя', 'Ұялы байланыс желіліерін тұншықтарғыш құралдың радиусы'),
        new Language('причина отсутствия глушителя', 'Ұялы байланыс желіліерін тұншықтарғыш құралдың болмау себебі'),
        new Language('Введите причину отсутствия оборудования (Обязательно)', 'Құрал-жабдықтың болмау себебін көрсету (міндетті түрде)'),
        new Language('Получение отчета', 'Есепті алу'),
        new Language('Обновить описание из НОБД', 'Обновить описание из НОБД'),
        new Language('Обновить контингент из НОБД', 'Обновить контингент из НОБД'),
        new Language('Обновление данных...', 'Обновление данных...')];

    getLang() {
       return from([this.Lang]);
    }

}
