import {Component, OnInit} from '@angular/core';
import {AppService} from '../shared/app.service';
import {PpentModel} from '../shared/model/ppent.model';
import {AuthModel} from '../shared/model/auth.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../shared/auth.service';
import {LangRepository} from '../shared/lang/lang.repository';
import {MessageModel} from '../schools/shared/model/message.model';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['auth.component.css']
})

export class AuthComponent implements OnInit{

  caret: boolean = false;
  myForm: FormGroup;
  ppentList: PpentModel[];
  ppentUserList: PpentModel[];
  password: string;
  message: MessageModel;

  constructor(
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {

      this.myForm = this.formValidation(null, null);

      this.message = new MessageModel();

      this.appService.getPpent()
        .subscribe(data => {
          this.ppentList = data.items;
          // получаем список пользователей  ppentList[0].id  - передаем id ппент по умолчанию который должен быть активен
          this.appService.getUsersPpent(this.ppentList[0].id)
            .subscribe(user => {
              console.log(user);
              this.ppentUserList = user.items;
              this.myForm = this.formValidation(this.ppentList[0].id, this.ppentUserList[0].id);
            });
        });
  }

  getUsers() {
      this.appService.getUsersPpent(+this.myForm.value.ppentId)
        .subscribe(data => {
          this.ppentUserList = data.items;
          console.log(this.myForm.value.ppentId, this.ppentUserList[0].id)
          this.myForm = this.formValidation(+this.myForm.value.ppentId, this.ppentUserList[0].id);
        })
  }

  auth() {
      console.log(this.myForm.value)
      this.appService.auth(this.myForm.value)
        .subscribe(data => {
          sessionStorage.setItem('user', JSON.stringify(data.data));
          let find = this.ppentList.find(item => item.id === this.myForm.value.ppentId);
          console.log(find);
          sessionStorage.setItem('ppentName', JSON.stringify(find));
          if (data.data.orgStatusId === 1) {
            this.router.navigate(['check-in', {auth: 'true'}]);
          } else {
            this.router.navigate(['collect']);
          }
          this.authService.login();
        }, error => {
          console.log(error);
          this.showMessage(error.errorMsgRus, 'danger');
        })
  }


  showMessage(text, status) {
    this.message.text = text;
    this.message.type = status;
    setTimeout(() => {
      this.message.text = '';
    }, 3500);
  }

  formValidation (ppentId: number, userId: number) {
    return new FormGroup({
      ppentId: new FormControl(ppentId, [Validators.required]),
      userId: new FormControl(userId, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
  }




}
