import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {SchoolModel} from '../shared/model/school.model';
import {EditSchoolComponent} from './edit-school/edit-school.component';
import {SchoolsService} from '../shared/services/schools.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuditModel} from '../../audit/shared/model/audit.model';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-full-info',
  templateUrl: './full-info.component.html',
  styleUrls: ['./full-info.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class FullInfoComponent implements OnInit, OnChanges {

  @Input()
  fullInfo: SchoolModel;

  @Input()
  oblId: any;

  @Input()
  schoolId: any;

  @Input()
  raionId: any;

  loadingBtnTxt = false;
  loadingBtnKont = false;
  updateTxtF = this.langRepository.wordList[303];
  updateTxtS = this.langRepository.wordList[304];


  @ViewChild(EditSchoolComponent)
  editSchool: EditSchoolComponent;

  @Output()
  deleteSchool: EventEmitter<any> = new EventEmitter();



  constructor(
    private schoolsService: SchoolsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public langRepository: LangRepository
              ) { }

  ngOnInit() {
    this.fullInfo = new SchoolModel();
  }

  ngOnChanges() {

  }

  update(updatePupilCount: any, fullInfo: SchoolModel){
    let data: SchoolModel;
    // Проверка того какая кнопка была нажата
    if(!updatePupilCount){
      this.loadingBtnTxt = true;
      this.updateTxtF = this.langRepository.wordList[305];
    } else {
      this.updateTxtS = this.langRepository.wordList[305];
      this.loadingBtnKont = true;
    }
    // Обновление данных
    this.schoolsService.updateData(fullInfo.id, data, updatePupilCount)
      .subscribe((res: any) => {
        this.updateInfo(res.data);
        if(!updatePupilCount){
          this.loadingBtnTxt = false;
          this.addSingle('Данные школы успешно обновлены');
          this.updateTxtF = this.langRepository.wordList[303];
        } else {
          this.loadingBtnKont = false;
          this.addSingle('Данные контингента успешно обновлены');
          this.updateTxtS = this.langRepository.wordList[304];
        }
      }, error => {
        this.addSingle(error.errorMsgRus);
        this.loadingBtnTxt = false;
        this.loadingBtnKont = false;
        this.updateTxtF = this.langRepository.wordList[303];
        this.updateTxtS = this.langRepository.wordList[304];
    });
  }

  show() {
    this.editSchool.show(this.raionId, this.oblId, this.fullInfo.id);
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }

  delete() {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[146],
      accept: () => {
        this.schoolsService.deleteSchool(this.fullInfo.id)
          .subscribe(data => {
            this.deleteSchool.emit(this.fullInfo.id);
            this.addSingle(this.langRepository.wordList[147]);
          }, error => {

          })

      }
    });
  }

  updateInfo(data) {
    for (let key in data) {
      this.fullInfo[key] = data[key];
    }
  }





}
