import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CollectDataService} from '../../shared/services/collect-data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MetalloiskatelModel} from '../../shared/model/metalloiskatel.model';
import {VideoRegistratorComponent} from '../video-registrator.component';
import {VideoRegistModel} from '../../shared/model/video-regist.model';
import {CatalogRepository} from '../../shared/services/catalog.repository';
import {SelectItem} from 'primeng/api';
import {UpdateService} from "../../shared/services/update.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-video-registrator',
  templateUrl: './edit-video-registrator.component.html',
  styleUrls: ['./edit-video-registrator.component.css']
})
export class EditVideoRegistratorComponent implements OnInit {

  @Output()
  update: EventEmitter<any> = new EventEmitter();


  idVpt: string;
  display: boolean;
  myForm: FormGroup;
  reasonStatus: boolean = false;

  yesAndNo: SelectItem[];

  constructor(
    public collectService: CollectDataService,
    public catalogRepository: CatalogRepository,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new VideoRegistModel());

    this.yesAndNo = [
      {label: 'Есть', value: 1 },
      {label: 'Нет', value:  0}
    ];
  }


  save() {

    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.updateSilencerUnit('videoRecorder', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }


  show(data: VideoRegistModel, idVpt: string) {
    this.idVpt = idVpt;
    this.display = true;

    this.collectService.getSingleEquipmentById('videoRecorder', data.dvrId)
      .subscribe(data => {
        this.myForm = this.formValidation(data.data);
      })


  }


  formValidation(data: VideoRegistModel) {
    return new FormGroup(
      {
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        capacity: new FormControl(data.capacity, [Validators.required]),
        recordingTime: new FormControl(data.recordingTime, [Validators.required]),
        blindArea: new FormControl(data.blindArea, [Validators.required]),
        onlineTranslation: new FormControl(data.onlineTranslation, [Validators.required]),
        recordingFormatId: new FormControl(data.recordingFormatId, [Validators.required]),
        playbackVideoStatusId: new FormControl(data.playbackVideoStatusId, [Validators.required]),
        continuousRecordingTime: new FormControl(data.continuousRecordingTime, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        dvrId: new FormControl(data.dvrId, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required]),
      }
    )
  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }



  close() {
    this.myForm.reset();
    this.display = false;
  }

}
