import {Http, Headers, RequestOptions, Response} from '@angular/http';
import { Observable} from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators'

export class BaseApi {
  // public baseUrl = 'https://bazappent.testcenter.kz/';
  public baseUrl = './';
  // private baseUrl = 'http://192.168.200.19:28080';



  constructor(public http: Http) {

  }

  private getUrl(url: string = ''): string {
    return this.baseUrl + url;
  }

  public get(url: string = ''): Observable<any> {
    return this.http.get(this.getUrl(url), this.token())
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }

  public getWithoutToken(url: string = ''): Observable<any> {
    return this.http.get(this.getUrl(url))
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }

  public post(url: string = '', data: any = {}): Observable<any> {
    return this.http.post(this.getUrl(url), data, this.token())
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }


  public postAuth(url: string = '', data: any = {}): Observable<any> {
    return this.http.post(this.getUrl(url), data)
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }



  public put(url: string = '', data: any = {}): Observable<any> {
    return this.http.put(this.getUrl(url), data, this.token())
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }




  public delete(url: string) {
    return this.http.delete(this.getUrl(url), this.token())
      .pipe(
        map((response: Response) => response.json()),
        catchError((error) => {
          throw(error.json());
        })
      );
  }

  public token() {
    const myHeaders = new Headers({ 'token': JSON.parse(sessionStorage.getItem('user')).token  });

    return new RequestOptions({ headers: myHeaders })
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

}
