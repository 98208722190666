import {Injectable} from '@angular/core';
import {CatalogService} from './catalog.service';
import {SelectItem} from 'primeng/api';

@Injectable()
export class CatalogRepository {

    evaluateLists: SelectItem[] = [];
    equipmentLists: SelectItem[] = [];
    countLists: SelectItem[] = [];
    techBrandsLists: SelectItem[] = [];
    metalTypes: SelectItem[] = [];
    manufacturesLists: SelectItem[] = [];
    recordingFormats: SelectItem[] = [];
    playbackVideoStatusesLists: SelectItem[] = [];
    reasonsAbsenceList: SelectItem[] = [];
    balanceList: SelectItem[] = [];



    // Марка оборудования
    glushTechBrandsList: SelectItem[] = [];
    metalTechBrandsList: SelectItem[] = [];
    videoTechBrandsList: SelectItem[] = [];
    conderTechBrandsList: SelectItem[] = [];
    cameraTechBrandsList: SelectItem[] = [];
    computerTechBrandsList: SelectItem[] = [];
    scannerTechBrandsList: SelectItem[] = [];
    keyTechBrandsList: SelectItem[] = [];



    constructor(private catalogService: CatalogService) {
      this.getTechEvaluates();
      this.getStaticDataForUnitName();
      this.getCount();
      this.getAllTechBrands();
      this.getMetalDetectorTypes();
      this.getRecordingFormats();
      this.getPlaybackVideoStatuses();
      this.getWhyNotHaveReason();

      this.balanceList = [
        {
          label: 'Нет',
          value: 'Нет'
        },
        {
          label: 'НЦТ',
          value: 'НЦТ'
        },
        {
          label: 'ВУЗА',
          value: 'ВУЗА'
        },
        {
          label: 'ШКОЛЫ',
          value: 'ШКОЛЫ'
        },
        {
          label: 'РОО(ГОО)',
          value: 'РОО(ГОО)'
        }
      ];
    }

   // Рабочее состояние
  getTechEvaluates() {
      this.catalogService.getTechEvaluates('techDataEvaluates')
        .subscribe(data => {
          console.log(data, 'раб сос');
          this.pushArray(data.data.catalog, this.evaluateLists, 'value');
        })
  }


  // страна производителя
  getCount() {
    this.catalogService.getTechEvaluates('manufacturers')
      .subscribe(data => {
        this.pushArray(data.data.catalog, this.countLists, 'value')
      })
  }

  getAllTechBrands() {
      this.techBrands(this.glushTechBrandsList, 1);
      this.techBrands(this.metalTechBrandsList, 2);
      this.techBrands(this.videoTechBrandsList, 3);
      this.techBrands(this.cameraTechBrandsList, 5);
      this.techBrands(this.metalTechBrandsList, 9);
  }

  // марка оборудования
  techBrands(lists, id: number) {
      return this.catalogService.getTechEvaluates(`techBrands/equipmentTypeId/${id}`)
        .subscribe(data => {
          console.log(data.data, 'марка');
          this.pushArray(data.data.catalog, lists, 'value');
        })
  }

  getMetalDetectorTypes() {
      return this.catalogService.getTechEvaluates('metalDetectorTypes')
        .subscribe(data => {
          this.pushArray(data.data.catalog, this.metalTypes, 'value')
        })
  }

  getRecordingFormats() {
    return this.catalogService.recordingFormats()
      .subscribe(data => {
        this.pushArray(data.data.catalog, this.recordingFormats, 'value');
      })
  }

  getStaticDataForUnitName() {
      return this.catalogService.getStaticDataForUnitName()
        .subscribe(data => {
          console.log(data);
          this.pushArray(data.data.catalog, this.equipmentLists, 'value')
        })
  }

  getPlaybackVideoStatuses() {
    return this.catalogService.playbackVideoStatuses()
      .subscribe(data => {
        this.pushArray(data.data.catalog, this.playbackVideoStatusesLists, 'value')
      })
  }

  nameEvaluates(id: number, array: string) {
      const find = this[array].find(item => item.value === id);
      if (find) {
        return find.label;
      }

  }


  // причины отсутствия оборудования
  getWhyNotHaveReason() {
     return this.catalogService.getWhyNotHaveReason()
       .subscribe(data => {
         console.log(data.data.catalog, 'вава');
         data.data.catalog.forEach(item => {
           this.reasonsAbsenceList.push({
             label: item.value,
             value: item.id
           })
         });

       }, error => {
         console.log(error);
       })
  }



  pushArray(data, arr, type) {
      console.log(data)
    data.forEach(item => {
      arr.push({
        label: item[type],
        value: item.id
      })
    })
  }
}
