import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-video-record',
  templateUrl: './add-video-record.component.html',
  styleUrls: ['./add-video-record.component.css']
})
export class AddVideoRecordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
