export class PrinterModel {
  constructor(
    public printerId: number = null,
    public quantity: number = null,
    public year: number = null,
    public brandId: number = null,
    public manufacturerId: number = null,
    public evaluateId: number = null,
    public whyNotHaveReason: string = null,
    public whyNotHaveId: number = null
  ) {

  }
}
