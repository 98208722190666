import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CatalogRepository} from "../../shared/services/catalog.repository";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ScannerModel} from "../../shared/model/scanner.model";
import {ActivatedRoute} from "@angular/router";
import {CollectDataService} from "../../shared/services/collect-data.service";
import {UpdateService} from "../../shared/services/update.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-scanner',
  templateUrl: './edit-scanner.component.html',
  styleUrls: ['./edit-scanner.component.css']
})
export class EditScannerComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  idVpt: string;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor(
    public catalogRepository: CatalogRepository,
    public route: ActivatedRoute,
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new ScannerModel());
    this.idVpt = this.route.snapshot.params['id'];

  }

  close() {
    this.myForm.reset();
    this.display = false;
  }


  show(data, idVpt) {
    this.display = true;
    this.idVpt = idVpt;
    this.collectService.getSingleEquipmentById('scanner', data.scannerId)
      .subscribe(data => {
        this.myForm = this.formValidation(data.data);
      })


  }

  formValidation(data) {
    return new FormGroup(
      {
        scannerId: new FormControl(data.scannerId, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        quantity: new FormControl(data.quantity, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        evaluateNotHaveReason: new FormControl(data.evaluateNotHaveReason, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required]),
      }
    )
  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }


  edit() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.updateSilencerUnit('scanner', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      }, error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }
}
