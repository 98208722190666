import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {VideoRegistModel} from '../shared/model/video-regist.model';
import {ActivatedRoute} from '@angular/router';
import {UpdateService} from '../shared/services/update.service';
import {EditVideoRegistratorComponent} from './edit-video-registrator/edit-video-registrator.component';
import {AddVideoRegistratorComponent} from './add-video-registrator/add-video-registrator.component';
import {CatalogRepository} from '../shared/services/catalog.repository';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-video-registrator',
  templateUrl: './video-registrator.component.html',
  styleUrls: ['./video-registrator.component.css']
})
export class VideoRegistratorComponent implements OnInit {

  @ViewChild(EditVideoRegistratorComponent)
  editVideoRegistratorComponent: EditVideoRegistratorComponent;


  @ViewChild(AddVideoRegistratorComponent)
  addVideoRegistratorComponent: AddVideoRegistratorComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  videoRegistratorLists: VideoRegistModel[] = [];
  currentVideoRegistrator: VideoRegistModel;
  idVpt: string;

  constructor(
    private collectService: CollectDataService,
    private route: ActivatedRoute,
    private updateService: UpdateService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.idVpt = this.route.snapshot.params['id'];

    this.currentVideoRegistrator = new VideoRegistModel();

    this.idVpt = this.route.snapshot.params['id'];

    this.collectService.getEquipmentData('videoRecorder')
      .subscribe(data => {
        console.log(data);
        this.videoRegistratorLists = data.list;
      })
  }

  activeAudit(videoRegistrator: VideoRegistModel) {
    console.log(videoRegistrator)
    this.currentVideoRegistrator = videoRegistrator;
  }

  edit() {
    this.editVideoRegistratorComponent.show(this.currentVideoRegistrator, this.idVpt);
  }

  update(event) {
    console.log(event)
    this.updateService.update(event, this.videoRegistratorLists, 'dvrId');
  }

  add() {
    this.addVideoRegistratorComponent.show();
  }

  push(event) {
    this.updateService.dataPush(event, this.videoRegistratorLists);
  }

  deleteVideoRegistrator() {
    this.delete.emit({
      equipmentId: this.currentVideoRegistrator.dvrId,
      equipmentName: 'videoRecorder',
      equipmentIdName: 'dvrId',
      equipmentLists: this.videoRegistratorLists
    });
  }

}
