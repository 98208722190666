import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SchoolsService} from '../../shared/services/schools.service';
import {PupilModel} from '../../shared/model/pupil.model';
import {ReferenceBooksService} from '../../shared/services/reference-books.service';
import {ReferenceBooksRepository} from '../../shared/services/reference-books.repository';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-pupil-edit',
  templateUrl: './pupil-edit.component.html',
  styleUrls: ['./pupil-edit.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PupilEditComponent implements OnInit {

  display: boolean = false;
  pupilInfo: PupilModel;
  myForm: FormGroup;
  classNumbers: any[];
  classLang: any[];
  pupulId: number;
  schoolId: number;

  @Output()
  editData: EventEmitter<any> =  new EventEmitter();

  constructor(
    public schoolsService: SchoolsService,
    public referenceBooksService: ReferenceBooksService,
    public referenceBooksRepository: ReferenceBooksRepository,
    public messageService: MessageService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.pupilInfo = new PupilModel(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null);
    this.myForm = this.formValidation(new PupilModel())
  };

  getSinglePupil(schoolId: number, pupilId: number) {
    this.pupulId = pupilId;
    this.schoolId = schoolId;
    this.schoolsService.getSinglePupil(schoolId, pupilId)
      .subscribe(data => {
        this.display = true;
        this.myForm = this.formValidation(data.data);
      })
     this.referenceBooksRepository.getClassNumbers(schoolId)
       .subscribe(data => {
         this.classNumbers = data;
       })
    this.referenceBooksRepository.getLang(schoolId)
      .subscribe(data => {
        this.classLang = data;
      })
  };

  formValidation(data: any) {
    return new FormGroup({
      classNumId: new FormControl(data.classNumId, [Validators.required]),
      langId: new FormControl(data.langId, [Validators.required]),
      pupilCount: new FormControl(data.pupilCount, [Validators.required])
    });
  }

  edit() {
    this.schoolsService.editPupil(this.schoolId, this.pupulId, this.myForm.value)
      .subscribe(data => {
        this.editData.emit(data.data);
        this.display = false;
      }, error => {
        this.addSingle(error.errorMsgRus)
      })
  }



  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }



}
