import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CollectDataComponent} from './collect-data.component';
import {AuthGuard} from '../shared/auth.guard';




const routes: Routes = [
  { path: 'collect', canActivate: [AuthGuard], component: CollectDataComponent },
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CollectDataRouting {

}
