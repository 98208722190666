import {Component, OnInit, ViewChild} from '@angular/core';
import {AddVideoComponent} from "./add-video/add-video.component";
import {AddHardwareKeyComponent} from "./add-hardware-key/add-hardware-key.component";
import {SelectItem} from "primeng/api";
import {CollectDataService} from "../shared/services/collect-data.service";
import {KeyModel} from "../shared/model/key.model";
import {VideoRecordModel} from "../shared/model/video-record.model";
import {CatalogRepository} from "../shared/services/catalog.repository";
import {EditKeyComponent} from "./edit-key/edit-key.component";
import {UpdateService} from "../shared/services/update.service";
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-add-key',
  templateUrl: './add-key.component.html',
  styleUrls: ['./add-key.component.css']
})
export class AddKeyComponent implements OnInit {

  checked: boolean = false;

  yesAndNo: SelectItem[] = [];
  selectedStatus: any = [];

  videoRecord: any;
  hardWareKey: KeyModel;

  @ViewChild(EditKeyComponent)
  editKeyComponent: EditKeyComponent;

  @ViewChild(AddHardwareKeyComponent)
  addHardWareKey: AddHardwareKeyComponent;



  constructor(
    public collectDataService: CollectDataService,
    public catalogrRepository: CatalogRepository,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }



  ngOnInit() {
    this.hardWareKey = new KeyModel();
    this.videoRecord = new VideoRecordModel();
    this.getHardwareKey();
    // this.getVideoRecord();

    this.yesAndNo = [
      {label:'Есть', value:'Есть'},
      {label:'Нет', value: 'Нет'}
    ];
  }

  addKey() {
    this.addHardWareKey.show();
  }

  statusKey(event) {
    if (event.value === 'Нет') {
      this.collectDataService.addHardwareKey({
        inumber: 'Нет',
        inOrg: 'Нет'
      })
        .subscribe(data => {
          this.updateService.addSingle('Аппаратный ключ обновлен');
        });
    }

  }

  editKey() {
    this.editKeyComponent.show(this.hardWareKey);
  }

  getHardwareKey() {
    this.collectDataService.getHardwareKey()
      .subscribe(data => {
        console.log(data);
        this.hardWareKey = data.data;
        if (data.data.inumber === 'Нет') {
          this.selectedStatus = 'Нет';
        } else {
          this.selectedStatus = 'Есть';
        }
      }, error => {
        console.log(error);
      })
  }

  getVideoRecord() {
    this.collectDataService.getVideoRecord()
      .subscribe(data => {
        console.log(data);
        if (data.videoRecord) {
          this.videoRecord = data.videoRecord;
        }
      }, error => {
        console.log(error);
      })
  }


  updateKey(event) {
    this.hardWareKey = event;
  }


}
