import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GlushitelModel} from '../../shared/model/glushitel.model';
import {CatalogRepository} from '../../shared/services/catalog.repository';
import {CollectDataService} from '../../shared/services/collect-data.service';
import {UpdateService} from "../../shared/services/update.service";
import {LangRepository} from '../../../shared/lang/lang.repository';

@Component({
  selector: 'app-edit-glushitel',
  templateUrl: './edit-glushitel.component.html',
  styleUrls: ['./edit-glushitel.component.css']
})
export class EditGlushitelComponent implements OnInit {

  display: boolean = false;
  myForm: FormGroup;
  idVpt: string;
  reasonStatus: boolean = false;

  @Output()
  update: EventEmitter<any> = new EventEmitter();


  constructor(
    public catalogRepository: CatalogRepository,
    public collectService: CollectDataService,
    public updateService: UpdateService,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.myForm = this.formValidation(new GlushitelModel())
  }


  show(glush: any, idVpt: string) {
    this.idVpt = idVpt;
    this.display = true;

    this.collectService.getSingleEquipmentById('silencer', glush.silencerId)
      .subscribe(data => {
        this.myForm = this.formValidation(data.data);
      })

  }


  formValidation(data) {
    return new FormGroup(
      {
        quantity: new FormControl(data.quantity, [Validators.required]),
        year: new FormControl(data.year, [Validators.required]),
        radius: new FormControl(data.radius, [Validators.required]),
        radiusActive: new FormControl(data.radiusActive, [Validators.required]),
        radiusTele2: new FormControl(data.radiusTele2, [Validators.required]),
        radiusBeeline: new FormControl(data.radiusBeeline, [Validators.required]),
        radiusAltel: new FormControl(data.radiusAltel, [Validators.required]),
        brandId: new FormControl(data.brandId, [Validators.required]),
        manufacturerId: new FormControl(data.manufacturerId, [Validators.required]),
        evaluateId: new FormControl(data.evaluateId, [Validators.required]),
        silencerId: new FormControl(data.silencerId, [Validators.required]),
        wifi: new FormControl(data.wifi, [Validators.required]),
        g3: new FormControl(data.g3, [Validators.required]),
        g4: new FormControl(data.g4, [Validators.required]),
        whyNotHaveReason: new FormControl(data.whyNotHaveReason, [Validators.required]),
        whyNotHaveId: new FormControl(data.whyNotHaveId, [Validators.required]),
      }
    )
  }

  save() {
    if (this.myForm.value.quantity > 0) {
      this.myForm.value.whyNotHaveId = 0;
    }

    this.collectService.updateSilencerUnit('silencer', this.myForm.value)
      .subscribe(data => {
        this.display = false;
        this.update.emit(data.equipmentDto);
      },error => {
        this.updateService.addSingle(error.errorMsgRus, 'error');
      })
  }

  reset() {
    this.display = false;
  }

  reason(event) {
    if (event.value === 'Другое') {
      this.reasonStatus = true;
    } else {
      this.reasonStatus = false;
    }
  }

  otherReason(event) {
    this.myForm.controls['whyNotHaveReason'].patchValue(event.value);
  }

  close() {
    this.myForm.reset();
    this.display = false;
  }

}
