import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {CheckInComponent} from './check-in/check-in.component';
import {AuthGuard} from './shared/auth.guard';



const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'check-in', canActivate: [AuthGuard],  component: CheckInComponent },
  { path: 'auth', component: AuthComponent}
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
