import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchSchoolComponent} from './school-table/search-school/search-school.component';
import {AddSchoolComponent} from '../add-school/add-school.component';
import {SchoolTableComponent} from './school-table/school-table.component';
import {MessageService} from 'primeng/api';
import {LangRepository} from '../../shared/lang/lang.repository';
import {ReportComponent} from "./report/report.component";

@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent {

  @ViewChild(SearchSchoolComponent)
  searchSchoolComponent: SearchSchoolComponent;

  @ViewChild(AddSchoolComponent)
  addSchoolComponent: AddSchoolComponent;

  @ViewChild(SchoolTableComponent)
  schoolTableComponent: SchoolTableComponent;

  @ViewChild(ReportComponent)
  reportComponent: ReportComponent;

  constructor(
    public messageService: MessageService,
    public langRepository: LangRepository,

  ) {

  }

  schoolLists: any[];
  fullInfoData: any;
  oblId: number;
  raionId: number;
  schoolId: number;


  updateSchool(event) {
    this.schoolLists = event.school;
  }

  addSchool() {
    this.addSchoolComponent.showDisplay();
  }


  fullInfo(event) {
    this.fullInfoData = event.ppent;
    this.oblId = event.oblId;
    this.raionId = event.raionId;
    this.schoolId = event.ppent.id;
  }

  delete(schoolId: number) {
    console.log(schoolId, this.schoolTableComponent.school);
    const index = this.schoolTableComponent.school.findIndex(item => item.id === schoolId);
    this.schoolTableComponent.school.splice(index, 1);
  }

  schoolInfo(event) {
    this.addSingle('Школа успешно добавлена', 'success');
    const searchInfo = [this.schoolTableComponent.oblId, this.schoolTableComponent.raionId, this.schoolTableComponent.typeSchool];
    const addInfo = [event.oblId, event.raionId, event.schoolTypeId];
    console.log(addInfo, searchInfo)
    if (JSON.stringify(searchInfo) === JSON.stringify(addInfo)) {
      this.schoolTableComponent.school.push(event.data);
      this.addSingle(this.langRepository.wordList[137]);
    }
  }



  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }
}


