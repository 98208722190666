export class AuditModel {
  constructor(
    public num: number, /*Номер аудитории*/
    public nameKaz: string, /*Наименование аудитории на казахском*/
    public nameRus: string, /*Наименование аудитории на русском*/
    public addressKaz: string, /*Наименование аудитории на казахском*/
    public addressRus: string, /*Наименование аудитории на русском*/
    public placeCount: number, /*Количество посадочных мест в аудитории*/
    public buildingNameKaz: string, /*Наименование корпуса на казахском*/
    public buildingNameRus: string, /*Наименование корпуса на русском*/
    public floor: number, /*этаж, на котором расположена аудитория*/
    public hasScheme?: number, /*задана ли схема аудитории (0-нет, 1-да)*/
  ) { }
}
