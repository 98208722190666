import {Component, OnChanges, OnInit, Output, Input, EventEmitter} from '@angular/core';


import {PrintRepository} from '../print.repository';
import {ShowModel} from '../shared/model/show.model';
import {LangRepository} from '../shared/lang/lang.repository';
import {AuditModel} from '../shared/model/audit.model';



@Component({

  selector: 'app-outline',
  templateUrl: './outline.component.html',
  styleUrls: ['./outline.component.css']
})
export class OutlineComponent implements OnInit,  OnChanges  {

  private place: number;
  private line: number;
  public audit: any = [];
  private placeCount: any = [];
  public show: ShowModel;
  public schemeStatus: boolean = false;



  @Input()
  hasScheme: AuditModel;

  @Input()
  scheme: string;

  @Output()
  saveScheme: EventEmitter<any> = new EventEmitter();


  constructor(private  printRepository: PrintRepository, public langRepository: LangRepository) { }

  ngOnInit() {
    this.hasScheme = new AuditModel(null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null);
    this.show = new ShowModel('success', '');
  }

  ngOnChanges() {
      console.log('sdsd')
      if (this.hasScheme != null && this.hasScheme.hasScheme === 1) {
        console.log(this.hasScheme)
        this.getAudit();
        this.schemeStatus = true;
      } else {
        this.audit = [];
        this.schemeStatus = false;
      };
  }


  create(num?:number) {
    this.audit = [];
    let count = this.line * this.place;
    if (count > 250) {
      return  this.showMessage('в Аудиторий не должно быть мест больше чем 250');
    }
    for (let i = 1; i <= count; i++ ) {
      if (this.isInteger(i/this.place)) this.add(i);
    }
    this.placeCount = Array(+this.place).fill(0).map((x,i) => 'М'+(i+1));
  }

  add(num) {
    let i = (num - this.place +1);
    let row = new Array();
    for (i; i<= num; i++) {
      row.push(i);
    }
    this.audit.push(row);
  }

  isInteger(num) {
    return (num ^ 0) === num;
  }

  update(e) {
    let user = navigator;
    let num;
    if (user.appCodeName == 'Mozilla') {
      num = +e.target.innerHTML;
    } else {
      num = +e.toElement.innerHTML;
    }


    if (isNaN(num)) return;

    let summ = Math.ceil(num / this.place);
    let status: boolean = false;
    if (num!= 0) {
      for (let i = 0; i < this.audit.length; i++ ) {
        this.audit[i].forEach((item, j, arr) => {
          if (status && arr[j]!= null && arr[j]!= 'п') {
            arr[j] = item - 1;
          }
          if (item == num) {
            arr[j] = null;
            status = true;
          }
        })
      }
    } else {
      let prevEl = this.element(e.target);

      if (isNaN(prevEl)) {
        prevEl = 0;
      };
      let status = false;
      let parentElementIndex = e.target.parentElement.sectionRowIndex;

      for (let i = 0; i < this.audit.length; i++) {
        this.audit[i].forEach((item, j, arr) => {
          if (arr[j]!= null && status && !isNaN(item) ) {
            arr[j] = item +1;
            if (arr[j] == this.audit[parentElementIndex-1][e.target.cellIndex-1] && item == prevEl+1) {
                arr[j] = item;
            }
          }
          if (item == prevEl) {
              this.audit[parentElementIndex-1][e.target.cellIndex-1] = prevEl +1;
              status = true;
            }


          if (prevEl == 0) {
              this.audit[parentElementIndex-1][e.target.cellIndex-1] = prevEl +1;
              status = true;
              prevEl = null;
          }
        })
      }
    }
  }

  element(el) {
    if (el.innerText != '' &&  el.innerText != 'п' ) return +el.innerText;
    let n  = +el.previousElementSibling.innerHTML;

    if (isNaN(n) && el.previousElementSibling.innerText != "п") {
      let element = el.parentNode.previousElementSibling.children;
      return this.element(element[element.length-1]);
    }

    if (el.previousElementSibling.innerText != '' && el.previousElementSibling.innerText != 'п')  return +el.previousElementSibling.innerHTML;
    return this.element(el.previousElementSibling);
  }


  addPassage(index: number, type: string) {

    if (this.placeCount[index+1] == 'П') {
      return this.showMessage('Нельзя добавить больше одного прохода');
    }

    if (type!='П') {
      for (let i = 0; i < this.audit.length; i++) {
        this.audit[i].forEach((item, j, arr) => {
          if (j == index) {
            this.audit[i].splice(index+1, 0, 'п');
          }

        })
      }
      this.placeCount.splice(index+1, 0, 'П');
    } else {
      for (let i = 0; i < this.audit.length; i++) {
        this.audit[i].forEach((item, j, arr) => {
          if (j == index) {
            this.audit[i].splice(index, 1);
          }

        })
      }
      this.placeCount.splice(index, 1);
    }
  }

  addVerticalPassage(num: number, type: any) {
    if (this.lineIndex(this.audit[num+1]) == 'П') {
      return this.showMessage('Проходов не должно быть больше одного');
    }

    if (type == 'П') {
      this.audit.splice(num, 1);
    }  else {
      let audit = Array(+this.placeCount.length).fill(0).map((x,i) => 'п');
      this.audit.splice(num+1, 0, audit);
    }

  }

  lineIndex(arr) {
      let audit = Array(+this.placeCount.length).fill(0).map((x,i) => 'п');
      if (JSON.stringify(audit) == JSON.stringify(arr)) return 'П';
      let index = this.audit.indexOf(arr);
      let arrFilter = this.audit.filter(n => {
          if (index >= this.audit.indexOf(n)) return JSON.stringify(n) == JSON.stringify(audit);
          return false;
      });
      return 'ряд '+(index+1 - arrFilter.length);
  }


  save() {
     let auditLength = this.audit[this.audit.length-1];
     if (this.hasScheme.placeCount != this.lastNumber()) {
       return this.showMessage(`не удалость сохранить 
                              количество мест должен быть равен ${this.hasScheme.placeCount}`);
     }


     let auditInfo = this.audit.map(arr => {
     let text = '';
     for (let i = 0; i < arr.length; i++) {
          let arrIndex = arr[i] || '';
          if (i == 0) text += `ряд${this.audit.indexOf(arr)+1}|`;
              text += (`${arrIndex}|`).toUpperCase();
          }
            return `${text}`;
          });
          let scheme = {
            scheme: this.placeCount.join('|')+'|'+ auditInfo.join('')
          };

          this.saveScheme.emit(scheme);
          // this.dataSource.auditScheme(this.hasScheme.num, scheme)
          //   .subscribe(data => {
          //     this.hasScheme.hasScheme = 1;
          //     this.showMessage('Cхема успешно сохранена', 'success');
          //     this.schemeStatus = true;
          //   }, error => {
          //     this.showMessage(error.errorMsgRus);
          //   })

  }


  getAudit() {
    if (this.scheme) {
      if (this.scheme[0] === 'q') {
        const text = this.scheme.substr(2);
        this.transformScheme(text);
      } else {
        this.transformScheme(this.scheme);
      }
    }

    // this.dataSource.getAuditScheme(this.hasScheme.num)
    //   .subscribe(data => {
    //     this.transformScheme(data.data.scheme);
    //   }, error => {
    //     this.showMessage(error.errorMsgRus);
    //   })
  }


  transformScheme(text: string) {
     console.log(text)
     let arr = text.split('ряд');
     let placeCount = [];
     let audit = [];

      for (var i = 0; i < arr.length; i++ ) {
        if (i == 0 ) {
          placeCount = arr[i].split('|');
        } else {
          let a = arr[i].split('|')
          a.pop();
          a.shift();
          audit.push(a);
        }
      }

      for (var i = 0; i < audit.length; i++ ) {
        audit[i].forEach(function(item, j, arr) {
          switch(item) {
            case 'П':
              arr[j] = 'п';
              break;
            case '':
              arr[j] = null;
              break;
            default:
              arr[j] = +item;
          }

        })
   }

    placeCount.pop();
    this.placeCount = placeCount;
    this.audit = audit;
  }

  public showMessage(text: string, type: string = 'danger') {
    this.show = new ShowModel(type, text);
    window.setTimeout(() => {
      this.show.text = '';
    }, 5000);
  }

  status() {
    this.schemeStatus = false;
  }


  lastNumber() {
      let largestAmount;
      for (let i = 0; i < this.audit.length; i++) {
          for (let j = 0; j < this.audit[i].length; j++) {
            if (this.audit[i][j] != null && this.audit[i][j] != 'п') largestAmount = this.audit[i][j];
          }
      }
      return largestAmount;
  }


  printAudit(table: HTMLElement) {
     let ppentName = JSON.parse(sessionStorage.getItem('ppentName'));
     console.log(ppentName);
     this.printRepository.print(table, this.hasScheme, ppentName.nameRus);
  }


}



