import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SchoolsComponent} from './schools.component';
import {PupilCountListComponent} from './pupil-count-list/pupil-count-list.component';
import {SchoolListComponent} from './school-list/school-list.component';
import {PupilByDirectionComponent} from './pupil-by-direction/pupil-by-direction.component';
import {AuthGuard} from '../shared/auth.guard';
import {ReportComponent} from "./school-list/report/report.component";


const routes: Routes = [
  { path: 'schools', canActivate: [AuthGuard], component: SchoolListComponent },
  { path: 'pupil', canActivate: [AuthGuard],  component: PupilCountListComponent },
  { path: 'pupil-direction', canActivate: [AuthGuard],  component: PupilByDirectionComponent },
  { path: 'report', canActivate: [AuthGuard],  component: ReportComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class SchoolsRoutingModule {

}
