export class GlushitelModel {
  constructor(
    public brandId: number = null,
    public evaluateId: number = null,
    public idVpt: number = null,
    public manufacturerId: number = null,
    public quantity: number = null,
    public radius: number = null,
    public radiusActive: number = null,
    public radiusAltel: number = null,
    public radiusBeeline: number = null,
    public radiusTele2: number = null,
    public silencerId: number = null,
    public wifi: number = null,
    public g3: number = null,
    public g4: number = null,
    public whyNotHaveReason: number = null,
    public whyNotHaveId: number = null,
    public year: number = null
    ) {}
}
