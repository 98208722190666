import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {AuditComponent} from "./audit.component";
import {AuthGuard} from '../shared/auth.guard';
import {ReportComponent} from './report/report.component';

const routes: Routes = [
  { path: 'audit', canActivate: [AuthGuard], component: AuditComponent },
  { path: 'report-audit', canActivate: [AuthGuard], component: ReportComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AuthRoutingModule {

}

