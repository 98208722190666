import {Component, Input, ViewChild} from '@angular/core';
import {SearchSchoolComponent} from './school-list/school-table/search-school/search-school.component';
import {AddSchoolComponent} from './add-school/add-school.component';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.css'],
  providers: []
})
export class SchoolsComponent {


}
