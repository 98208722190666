import { Component, OnInit } from '@angular/core';

import {ConfirmationService, MessageService} from "primeng/api";
import {LangRepository} from '../../shared/lang/lang.repository';
import {AuditService} from '../shared/services/audit.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  display: boolean = false;
  isBase: boolean = false;
  schoolReportList: any[] = [];
  collegeReportList: any[] = [];
  schoolPupilCountList: any[] = [];
  displaySidebar: boolean = false;
  auditListReport: any[] = [];

  constructor(
    public langRepository: LangRepository,
    public auditService: AuditService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    if (JSON.parse(sessionStorage.getItem('user')).isBase){
      this.isBase = true;
    }

    this.getReportFileList('audit');



  }

  show() {
    this.display = true;
  }

  getReportFileList(type: string) {
    this.auditService.getReportFileList(type)
      .subscribe(data => {
        this.auditListReport = data.items;
      })
  }


  confirmDelete(recordId: number, type: string) {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[182],
      accept: () => {
        this.deleteReport(recordId, type);
      }
    });
  }

  deleteReport(recordId: number, type: string) {
    this.auditService.deleteReport(recordId, type)
      .subscribe(data => {
        const index = this.auditListReport.findIndex(item => item.id === recordId);
        this.auditListReport.splice(index, 1);
        this.addSingle(this.langRepository.wordList[185], 'success');
      }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  report(type: string, format: string = 'pdf') {
    const myHeaders = JSON.parse(sessionStorage.getItem('user')).token;

    window.open(`${this.auditService.baseUrl}api/ppent/reports/${type}?token=${myHeaders}&lang=${this.langRepository.langStatus}&format=${format}`)
  }

  upload(files, type: string) {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);
    formData.append('filename', files[0].name);

    this.auditService.uploadReportFile(formData, type)
      .subscribe(data => {
        this.auditListReport.push(data.data);
        this.addSingle(this.langRepository.wordList[186], 'success')
      }, error => {
        this.addSingle(error.errorMsgRus);
      })
  }

  dowload(reportId: number, type: string) {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`${this.auditService.baseUrl}api/ppent/reports/${type}/files/${reportId}?token=${token}`);
  }

  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }

}
