import { Component, OnInit } from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {BrandNameModel} from '../shared/model/brand-name.model';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UpdateService} from '../shared/services/update.service';

@Component({
  selector: 'app-catalog-management',
  templateUrl: './catalog-management.component.html',
  styleUrls: ['./catalog-management.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class CatalogManagementComponent implements OnInit {

  catalog: SelectItem[];
  display: boolean = false;
  myForm: FormGroup;
  format: string;


  constructor(private collectDataService: CollectDataService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public updateService: UpdateService
              ) { }

  ngOnInit() {
    this.collectDataService.getStaticDataForUnitName()
      .subscribe(data => {
        this.catalog = this.arrForEach(data.data.catalog);
      })

    this.myForm = new FormGroup({
      model: new FormControl(null, [Validators.required]),
      equipmentNameId: new FormControl(null, [Validators.required])
    });
  }

  show() {
    this.display = true;
  }


  arrForEach(data) {
    const arr: SelectItem[] = [];
    data.forEach(item => {
      arr.push({
        label: item.value,
        value: item.id,
      })
    });
    return arr;
  }

  addBrangName() {
    this.collectDataService.addBrandNameForUnit(this.myForm.value)
      .subscribe(data => {
        console.log(data);
        this.myForm.reset();
        this.updateService.addSingle('Оборудования успешно добавлена');
        this.display = false;
      })
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }

  reset() {
    this.myForm.reset();
    this.display = false;
  }

  addFormat() {
    const formatData = {
      format: this.format
    };
    this.collectDataService.addFormat(formatData)
      .subscribe(data => {
        console.log(data)
        this.updateService.addSingle('Формат успешно добавлен');
        this.format = '';
      })

  }
}


